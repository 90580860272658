import React, { useState, useEffect } from 'react';
//import { toast } from 'react-toastify'

import { Autocomplete, TextField } from '@mui/material';
import { GetVendors } from './hooks';

function FilterVendorName({
    setVendorName,
	VendorName,
	setVendorNameId,
	VendorNameId,
    required
}) {

    //console.log(required);
    const [lable,setLable] = useState('Vendor Name')
    
    useEffect(() => {
        if(required === 'true')
        {
            setLable('Vendor Name*')
        }
      }, [required]);


    const [options, setoptions] = useState([]);
	const client_list_hook = GetVendors();
	const [Focus, setFocus] = useState(false);

    //const [lable,setLable] = useState(required)

	useEffect(() => {
		if (client_list_hook.data) {
			setoptions(client_list_hook.data.data.map((currElement, index) => {
                //console.log("The current iteration is: " + index);
                //console.log(currElement.vendorName);
                //console.log("\n");
                return (currElement.vendorName)?currElement.vendorName:currElement.vendorId; //equivalent to list[index]
              }));//((itm) => (itm.vendorName)));
		}

        //console.log(options);
        
	}, [client_list_hook.data]);

    function changeProjectType(val) {
		//console.log(val);
		setVendorName(val);
		let obj = client_list_hook.data.data.find((o) => (o.vendorId === val || o.vendorName === val));

		if (obj) {
			setVendorNameId(obj._id);
		}
        else{
			setVendorNameId(null)
			setVendorName(null)
		}
	}

    return (
        <>
            <div>
                <div className="flex gap-3">
                    <div className="grow">
                        <div className="lightT">
                            {client_list_hook.isLoading ? (
                                <p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
                                    Vendor Name{' '}
                                    <span className="text-sm text-slate-400 ps-4 inline-block">
                                        Loading...
                                    </span>
                                </p>
                            ) : (                                
                                <Autocomplete
                                    disablePortal
                                    isOptionEqualToValue={(option, value) =>
                                        option === value || value === '' || value == null
                                    }
                                    id="combo-box-demo"
                                    options={options}
                                    value={VendorName}
                                    renderInput={(params) => (
                                        <TextField {...params} label={lable} />
                                    )}
                                    onChange={(event, value) => changeProjectType(value)}
                                    onFocus={() => setFocus(true)}
                                    onBlur={() => setFocus(false)}
                                    // onInputChange={(event, newInputValue) => {
                                    //     setProjectType(newInputValue);
                                    //   }}
                                />
                            )}
                            {VendorName == null && Focus && required === 'true' && (
								<small className="text-red-600 ">
									Vendor name is required
								</small>
							)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FilterVendorName;
