import { useQuery, useMutation } from '@tanstack/react-query';

import { useDispatch } from 'react-redux';

import { LogoutCheck } from '../utils';
import { buildAxiosInstance } from '../axiosInstance';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
export const GetCategorylist = () => {
	//const Data = useSelector(state => state.auth)
	const { instance } = useMsal();
	let dispatch = useDispatch();
	return useQuery({
		queryKey: ['get_category'],
		retry: false,
		queryFn: async () => {
			try {
				const TOAST_ID = 'get_category_list'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.get('/category')
				return responseData
				
			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

export const GetVendors = () => {
	
	const { instance } = useMsal();
	let dispatch = useDispatch();
	return useQuery({
		queryKey: ['get_vendors'],
		retry: false,
		queryFn: async () => {
			try {

				const TOAST_ID = 'get_vendors'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.get('/vendors')
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});

};

export const useGetRules = (global=null) => {
	let dispatch = useDispatch()
	const { instance } = useMsal();
	return useMutation({
		retry: false,
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'get_rules'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					params: {...body, global: global},
				};
				const responseData = await axiosInstance.get('/get_rules',config)
				return responseData


			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw error
			}
		}

	});
};


export const useUpdateRules = () => {
	let dispatch = useDispatch()
	const { instance } = useMsal();
	return useMutation({
		retry: false,
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'update_rules'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.post('/save_rules',body)
				let message = responseData?.message
				message && toast.success(message , {toastId: TOAST_ID})
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw error
			}
		}

	});
};