import React, { useState } from 'react';
import styles from '../invoice.module.css';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { AddKeyPopupSchema } from '../../YupSchema';

function FieldList({IsCSV, fieldsExtracted, setfieldsExtracted, setchangeDetect, DocLockedForMe , setchangeAdd, Freez}) {

	function handleCng(e, itmK) {
		let flds = [...fieldsExtracted];
		flds[itmK].value = e.target.value;
		setfieldsExtracted(flds);
		if (DocLockedForMe){
			setchangeDetect(true)
			setchangeAdd(p=>p+1)
		}

		
	}

	const [isOpened, setIsOpened] = useState(false);

	function DeletePop(indX) {
		swal('Are you sure you want to Delete?', {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				let flds = [...fieldsExtracted];
				flds.splice(indX, 1);
				setfieldsExtracted(flds);
				if (DocLockedForMe){
			setchangeDetect(true)
			setchangeAdd(p=>p+1)
		}

			}
		});
	}

	const formik = useFormik({
		initialValues: {
			Key: '',
			Validation: '',
		},
		validationSchema: AddKeyPopupSchema,
		onSubmit: (values) => {
			let tf = false
			fieldsExtracted.map(itm => {
				if (itm.entity.toLocaleUpperCase() === values.Key.toLocaleUpperCase()) {
					tf = true
				}
				return null
		});
			if (tf) {
				toast.error('Field already present');
			} else {
				setfieldsExtracted((p) => {
					let xp = [...p];
					// let filterFields = xp.filter(
					// 	(fldEnt) => fldEnt.entity === values.Key
					// );
					// let mxVersion = 0;
					// if (filterFields.length > 0) {
					// 	mxVersion = Math.max(...filterFields.map((it) => it.version)) + 1;
					// }
					xp.push({
						entity: values.Key.toLocaleUpperCase(),
						value: '',
						status: 'new added',
						version: 0,
						entity_valid: 'none',
						rule_applied: '',
						entity_validation_explanation: '',
						annotated: true,
						originalValue : null,
						bounding_box: []
						
					});
					return xp;
				});
				setIsOpened(false);
				if (DocLockedForMe){
			setchangeDetect(true)
			setchangeAdd(p=>p+1)
		}
				formik.setValues({ Key: '', Validation: '' });
			}
		},
	});

	function validateDate(e, itmK) {
		let flds = [...fieldsExtracted];
		let value = e.target.value
		const regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/;

		if (value === '' || regex.test(value)) {
			const [month, day, year] = value.split('/').map(Number);
			const date = new Date(year, month, day);
			const isValidDate = !isNaN(date.getTime());
			if (isValidDate) {
				flds[itmK].value = value;
				delete flds[itmK].error
				if (DocLockedForMe) {
					setchangeDetect(true)
					setchangeAdd(p => p + 1)
				}

			}
			else{
				flds[itmK].error = 'invalid date';
			}
		}
		else{
			flds[itmK].error = 'invalid date';
		}
		setfieldsExtracted(flds);

	}


	return (
		<>
			<div
				className={`${styles.invfieldsExtractedWrap} px-6 pt-4 overflow-auto`}
			>
				{/* List loop */}
				{fieldsExtracted !== null &&
					fieldsExtracted.map((itmK, indX) => {
						return (
							<div
							    key={`list_itm_${indX}`}
								className={`${styles.cLoop} flex gap-2 items-center text-sm`}
							>
								{!IsCSV && <button
								 	disabled={IsCSV || Freez || !DocLockedForMe}
									onClick={() => DeletePop(indX)}
									className="leading-none"
								>
									<span className="material-icons-outlined text-sm text-red-600">
										cancel
									</span>
								</button>}
								<div
									className={`${styles.invfldHLayer} w-1/3 pr-6 relative flex items-center`}
								>
									<span
										title={itmK.entity}
										className={`${styles.invfldName} ${styles.green} block text-gray-600`}
									>
										{itmK.entity}

									
									</span>

									
								</div>
								<div className="grow pr-0 py-1 relative">
									{itmK.entity.toLocaleLowerCase().includes('date') ?
									<input
									style={{border: itmK?.error && 'solid red 1px'}}
									disabled={IsCSV || Freez || !DocLockedForMe}
									onChange={(e) => handleCng(e, indX)}
									type="text"
									className={styles.invFldInput}
									onBlur={(e)=> validateDate(e, indX)}
									value={itmK.value}
									title={itmK.value}
										/> :  
									<input
									    disabled={IsCSV || Freez || !DocLockedForMe || itmK.entity.toLocaleLowerCase() === 'vendor'}
										onChange={(e) => handleCng(e, indX)}
										type="text"
										className={styles.invFldInput}
										value={itmK.value}
										title={itmK.value}
									/> }
									
								</div>
								<div className="flex-none w-6 leading-none">
									{itmK.entity_valid === 'true' && (
										<span
											title={itmK.entity_validation_explanation}
											className="material-icons text-sm leading-none cursor-default text-green-500"
										>
											check_circle
										</span>
									)}
									{itmK.entity_valid === 'false' && (
										<span
											title={itmK.entity_validation_explanation}
											className="material-icons text-sm leading-none cursor-default text-red-500"
										>
											check_circle
										</span>
									)}
									{itmK.entity_valid === 'none' && (
										<span
											title={itmK.entity_validation_explanation}
											className="material-icons text-sm leading-none cursor-default text-gray-500"
										>
											check_circle
										</span>
									)}
								</div>
								
							</div>
						);
					})}
			</div>

			{isOpened && (
				<div className={`flex px-6 pt-4 gap-4 border-t mt-2`}>
					<div className="flex-none w-44 py-1 relative">
						<input
							placeholder="+ Add Field Name"
							id="Key"
							value={formik.values.Key}
							onChange={(e) => formik.setFieldValue('Key', e.target.value)}
							type="text"
							className={`${styles.invFldInput} h-8`}
						/>
						{formik.errors.Key && formik.touched.Key && (
							<small className="text-red-500">{formik.errors.Key}</small>
						)}
					</div>

					
				</div>
			)}

			<div className="flex gap-3 px-6 py-2">
				{isOpened ? (
					<div className="flex gap-2 w-44">
						<button
							type="button"
							onClick={() => {
								formik.setFieldValue('Key', '')
								setIsOpened((wasOpened) => !wasOpened)
							}
							}
							className="border border-blue-300 px-4 py-2 rounded-md text-primary text-sm flex items-center font-black"
						>
							Cancel
						</button>
						<button
							type="button"
							disabled={formik.values.Key === ''}
							onClick={formik.handleSubmit}
							className="bg-blue-200 hover:bg-indigo-400 px-4 py-2 rounded-md text-primary text-sm hover:text-white flex items-center font-black disabled:bg-gray-200 disabled:text-gray-500 disabled:hover:text-gray-500"
						>
							<span className="material-icons text-sm mr-1 ml-0">check</span>Save
						</button>
					</div>
				) : (
					<div className='w-44 text-left pt-2'>
						{!IsCSV && <button
							disabled={IsCSV || Freez || !DocLockedForMe}
							type="button"
							onClick={() => setIsOpened((wasOpened) => !wasOpened)}
							className="bg-blue-200 hover:bg-indigo-400 px-4 py-2 rounded-md text-primary text-sm hover:text-white flex items-center font-black disabled:bg-gray-200 disabled:text-gray-500 disabled:hover:text-gray-500"
						>
							<span className="material-icons text-sm mr-1">add</span>Add Field
						</button>}
					</div>
				)}
				
			</div>

			
		</>
	);
}

export default FieldList;
