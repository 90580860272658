import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../shared/common.module.css';
import { useDispatch } from 'react-redux';
import { dispClientId, dispClientPageNo } from '../navigateSlice';

function DashCounters({ DashboardData }) {
	let dispatch = useDispatch();
	return (
		<>
			<div className="grid grid-cols-2 lg:grid-cols-4 gap-4 px-6 pb-7 border-b">
				<div
					className={`${styles.counterBox} p-4 flex flex-col justify-end text-primary`}
				>
					<p className="font-black text-xl">{DashboardData.total_client}</p>
					<p>Total Clients</p>
				</div>
				<div
					className={`${styles.counterBox} p-4 flex flex-col justify-end text-primary`}
				>
					<p className="font-black text-xl">{DashboardData.total_vendor}</p>
					<p>Total Vendors</p>
				</div>
				<div
					className={`${styles.counterBox} p-4 flex flex-col justify-end text-primary`}
				>
					<p className="font-black text-xl">{DashboardData.total_invoice_uploaded}</p>
					<p>Total Invoices</p>
				</div>
				<div
					className={`${styles.counterBox} p-4 flex flex-col justify-end text-primary`}
				>
					<p className="font-black text-xl">{DashboardData.total_contract}</p>
					<p>Total Contracts</p>
				</div>
			</div>
		</>
	);
}

export default DashCounters;
