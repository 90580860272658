import { Outlet, useSearchParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import React, { useState, useCallback, useEffect } from 'react';
//import { useParams } from 'react-router-dom'
import { useInvoiceslistPagewise, useSoftDeleteFailedFiles, useInvoicesCsvlistPagewise, useSoftDeleteCsvFailedFiles } from './hooks';
//import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { RECORDS_PER_PAGE } from '../../config';
//import { dispInvoicePageNo } from '../navigateSlice';
import LoaderFull from '../shared/loader-full-screen';
import { useDebouncedState } from '@mantine/hooks';
import { decodeName, } from '../utils';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useGetSasToken } from './hooks';
import {  BLOB_URL } from '../../config';

function ListInvoicesIndexByStatus() {
	//const navigateRedux = useSelector(state => state.navigateSlice)
	const [searchParams, setSearchParams] = useSearchParams();

	const delete_inv_hook = useSoftDeleteFailedFiles()
	//let dispatch = useDispatch()

	const [selectedInvoices, setselectedInvoices] = useState(new Set([]))

	const [DebounceSearch] = useDebouncedState('', 500);
	let sortArg = ''
	if (searchParams.get('invoice_list_sort_key') === '' || searchParams.get('invoice_list_sort_key') === null) {
		sortArg = ''
	}
	else {
		sortArg = [searchParams.get('invoice_list_sort_key'), searchParams.get('invoice_list_sort_order')].join(' ')
	}
	const invoices_list_hook = useInvoiceslistPagewise(
		decodeName(searchParams.get('client_id')),
		decodeName(searchParams.get('vendor_id')),
		decodeName(searchParams.get('category_name')),
		(parseInt(searchParams.get('invoice_list_page_no')) || 1),
		sortArg
	)

	const renderList = useCallback(() => {
		if (invoices_list_hook.hook.isFetching || delete_inv_hook.isPending) {
			return <LoaderFull />;
		} else if (invoices_list_hook.hook.data?.data?.length === 0) {
			return <p className="text-center">No data found</p>;
		} else {
			return (
				<Outlet
					context={{
						invoiceList: invoices_list_hook.hook.data,
						Search: DebounceSearch,
						selectedInvoices: selectedInvoices,
						setselectedInvoices: setselectedInvoices
					}}
				/>
			);
		}
	}, [invoices_list_hook.hook, DebounceSearch, selectedInvoices, delete_inv_hook.isPending]);

	function handleCheckAll(checked) {
		if (checked) {
			setselectedInvoices(new Set(invoices_list_hook.hook?.data?.data.map(itm => itm._id)))
		}
		else {
			setselectedInvoices(new Set([]))
		}
	}

	function deleteInvClick() {

		swal('Are you sure you want to delete this? This action is permanent and cannot be undone.', {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				// User clicked "Yes" button
				let body = {
					document_ids: Array.from(selectedInvoices)
				}
				delete_inv_hook.mutate(body)
			} else {
				// User clicked "No" button or closed the dialog
			}
		});
	}

	useEffect(() => {
		if (delete_inv_hook.isSuccess) {
			toast.success("The failed files has been deleted")
			setselectedInvoices(new Set([]))
			invoices_list_hook.hook.refetch()
		}
	}, [delete_inv_hook.isSuccess])


	//csv code start

	const [toggle, setToggle] = useState({});

	const [searchParamsCsv, setSearchParamsCsv] = useSearchParams();

	const delete_inv_csv_hook = useSoftDeleteCsvFailedFiles()
	//let dispatch = useDispatch()

	const [selectedCsvInvoices, setselectedCsvInvoices] = useState(new Set([]))

	const [DebounceCsvSearch] = useDebouncedState('', 500);
	let sortCsvArg = ''
	if (searchParamsCsv.get('invoice_csv_list_sort_key') === '' || searchParamsCsv.get('invoice_csv_list_sort_key') === null) {
		sortArg = ''
	}
	else {
		sortArg = [searchParamsCsv.get('invoice_csv_list_sort_key'), searchParamsCsv.get('invoice_csv_list_sort_order')].join(' ')
	}
	const invoices_csv_list_hook = useInvoicesCsvlistPagewise(
		decodeName(searchParamsCsv.get('client_id')),
		decodeName(searchParamsCsv.get('vendor_id')),
		decodeName(searchParamsCsv.get('category_name')),
		(parseInt(searchParamsCsv.get('invoice_csv_list_page_no')) || 1),
		sortCsvArg
	)

	function handleCsvCheckBoxRowwise(checked, doc_id) {
		if (checked) {
			let tp = new Set(selectedCsvInvoices)
			tp.add(doc_id)
			setselectedCsvInvoices(tp)
		}
		else {
			let tp = new Set(selectedCsvInvoices)
			tp.delete(doc_id)
			setselectedCsvInvoices(tp)
		}
	}

	const get_sas_token = useGetSasToken()

	async function handlecsvNavigate(obj,ind) {
		
		let body = { 
			path:BLOB_URL+"/"+obj.blob_path
		}
		get_sas_token.mutate(body)

	}

	function handleToggle(masterId) {
		setToggle(prev => {
			let ob = { ...prev }
			if (masterId in ob) {
				ob[masterId] = !ob[masterId]
			}
			else {
				ob[masterId] = true
			}
			return ob
		})
	}

	useEffect(() => {
		if (get_sas_token.isSuccess) {
			const data = get_sas_token.data;
			console.log(data);

			window.open(data.path + '?' + data.sas_token, '_blank');

		}
	}, [get_sas_token.isSuccess])

	const renderCsvList = useCallback(() => {
		if (invoices_csv_list_hook.hook.isFetching || delete_inv_csv_hook.isPending) {
			return <LoaderFull />;
		} else if (invoices_csv_list_hook.hook.data?.data?.length === 0) {
			return <p className="text-center">No data found</p>;
		} else {
			return (
				<>
					{invoices_csv_list_hook.hook.data?.data?.map((itm, ind) => {
						return (
							<div key={`vendor` + ind}>
								<div className='tblLoop flex gap-2 mb-2'>
									<div className="flex-none w-[40px] flex items-center justify-center">
										<Checkbox size="small" onChange={(e) => handleCsvCheckBoxRowwise(e.target.checked, itm._id)} checked={selectedCsvInvoices.has(itm._id)} />
									</div>
									<div className="flex-none w-[50px]">
										<label className="vLBG flex items-center px-3 h-[53px] rounded-md text-center text-white relative text-sm">
											<img className="h-8" src="/svg/ri_building-fill.svg" alt="" />
										</label>
									</div>
									<div className='flex-none flex items-center nonShadow w-5'>
										{itm?.reason?.error_list?.filter(item => (item.hasOwnProperty('error_message'))).length > 0 &&
											<button
												onClick={() => handleToggle(itm?._id)}
											>
												{toggle[itm._id] ?
													<span className="material-icons text-md text-black">keyboard_arrow_up</span>
													:
													<span className="material-icons text-md text-black">keyboard_arrow_down</span>
												}
											</button>}
									</div>
									<div className="grow border rounded-md px-5 py-3 text-sm flex items-center justify-between">
										<div className="min-w-[135px] relative flex grow border-r pr-4 items-center">
											<span
												title={itm.name}
												className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
											>
												{itm.name}
											</span>
										</div>
										<div className="flex-none border-r px-0 text-center w-[200px] relative flex items-center">
											<span
												title={itm?.type}
												className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
											>
												csv
											</span>
										</div>
										<div className="flex-none border-r px-0 text-center w-[200px] relative flex items-center">
											<span
												title={itm.created_by}
												className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
											>
												{itm?.created_by}
											</span>
										</div>
										<div className="flex-none border-r px-4 text-center w-[200px]">
											{formatDate(itm.created_at)}
										</div>

										<div className="flex-none border-r px-4 text-center w-[150px]">
											<Tooltip title={(itm?.reason?.common_error_msg) ? itm?.reason?.common_error_msg : '-'} arrow>
												<span className="material-icons-outlined text-md text-primary">
													info
												</span>
											</Tooltip>
										</div>
									</div>
									<div className="flex-none flex w-[150px] pr-2">
										<button
											onClick={() => handlecsvNavigate(itm, ind)}
											type="button"
											className="w-[140px] vLBG block px-4 py-3 text-center text-primary text-sm rounded-md font-black mx-1 disabled:bg-gray-200 disabled:text-gray-500"
										>
											{(itm?.status ? itm?.status : 'Download')}
										</button>

									</div>
								</div>


								{toggle[itm?._id] && (
									<>
									
										{itm?.reason?.error_list?.filter(item => (item.hasOwnProperty('error_message') || item.error_message)).map((childItm, ind1) => {
												
											return (
												<>
												{ind1 === 0 &&
													<div className='flex items-center pl-[132px] py-2 text-xs'>
														<label className="block font-black w-[180px] pl-5 cursor-default"># Invoice</label>
														<label className="block font-black grow pl-5 cursor-default">Error Message</label>
													</div>
													
												}
												<div key={childItm._id} className='tblLoop flex gap-2 mb-2 pr-3 loopChild'>
													<div className='flex-none w-[126px]'>
														&nbsp;
													</div>

													<div className="grow border rounded-md pl-5 py-3 text-sm flex items-center justify-between h-14">
														<div className="relative flex w-40 border-r pr-4 items-center">
															<span
																title={childItm.invoice_no}
																className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
															>
																{/* {itm.master.fname} */}
																{childItm.invoice_no}
															</span>
														</div>
														<div className="grow border-r px-4 text-center relative flex items-center">
															<span
																title={childItm.error_message}
																className="absolute text-left w-full block text-ellipsis whitespace-nowrap overflow-hidden"
															>
																{childItm?.error_message?childItm?.error_message:'-'}
															</span>
														</div>
														
													</div>
												
												</div>
												</>
											)
											
										})}
									</>

								)}

							</div>

						);
					})}
				</>
			);
		}
	}, [invoices_csv_list_hook.hook, DebounceCsvSearch, selectedCsvInvoices, delete_inv_csv_hook.isPending]);

	function handleCsvCheckAll(checked) {
		if (checked) {
			setselectedCsvInvoices(new Set(invoices_csv_list_hook.hook?.data?.data.map(itm => itm._id)))
		}
		else {
			setselectedCsvInvoices(new Set([]))
		}
	}

	function deleteCsvInvClick() {

		swal('Are you sure you want to delete this? This action is permanent and cannot be undone.', {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				// User clicked "Yes" button
				let body = {
					document_ids: Array.from(selectedCsvInvoices)
				}
				console.log(body);

				delete_inv_csv_hook.mutate(body)
			} else {
				// User clicked "No" button or closed the dialog
			}
		});
	}

	useEffect(() => {
		if (delete_inv_csv_hook.isSuccess) {
			toast.success("The failed files has been deleted")
			setselectedCsvInvoices(new Set([]))
			invoices_csv_list_hook.hook.refetch()
		}
	}, [delete_inv_csv_hook.isSuccess])

	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter manageFlds">

				<div className="relative fldWrap">
					<Tabs>
						<TabList
							className={'ps-4 absolute top-[280px] left-[55px] lg:top-[150px]'}
						>
							<Tab>All Files</Tab>
							<Tab>CSV</Tab>
						</TabList>
						<TabPanel className="pt-0">
							<div className="lg:flex gap-4 justify-between items-center vLBG py-4 px-6">
								<div className="flex-none">
									<h1 className="text-lg font-normal text-primary">
										List of <strong className="font-black">Failed Files</strong>
									</h1>
									<p className="text-xs text-gray-500 mb-2">
										List of all Failed Files are given below -
									</p>
								</div>
								<div className="flex gap-3 flex-wrap">
									<div className="flex gap-2 xl:pr-7">
										<button type='button'
											className='bg-red-600 hover:bg-red-500 px-6 h-[40px] rounded-md text-sm font-black text-white flex gap-2 items-center disabled:bg-gray-200 disabled:text-gray-500 disabled:border disabled:border-gray-400'
											disabled={delete_inv_hook.isPending || selectedInvoices.size === 0} onClick={deleteInvClick}>
											<span className="material-icons-outlined text-md">delete</span>
											<span>Delete </span>
										</button>
									</div>
								</div>
							</div>
							<div className="px-6 pt-16 pb-6">
								<div className="listDHeight overflow-auto">
									{/* Table Head */}
									<div className="flex gap-2 mb-2 items-center pr-4">
										<div className="flex-none w-[40px]">&nbsp;
											<Checkbox
												disabled={invoices_list_hook.hook.isFetching}
												checked={selectedInvoices.size === invoices_list_hook.hook?.data?.data?.length && selectedInvoices.size !== 0}
												onChange={(e) => handleCheckAll(e.target.checked)} size="small" />
											<div className="text-[9px] text-center">Select All</div>
										</div>
										<div className="flex-none w-[55px]">
											<div className="flex-none text-center relative w-[55px]">&nbsp;
												{/* <span title='default sort'  className={`text-primary cursor-pointer  material-icons w-4 text-sm mt-1`}>
												swap_vert
											</span> */}
											</div>
										</div>
										<div className="grow px-2 py-1 flex items-center justify-between text-xs">
											<div className="grow pr-4">
												<label
													/* onClick={() => handleSort('fname')} */
													className={` block font-black relative w-[120px] cursor-default`}
												>
													Name of File
													{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
													swap_vert
												</span> */}
												</label>
											</div>
											<div className="flex-none px-2 text-center w-[200px]">
												<label
													// onClick={() => handleSort('uploaded_by')}
													className={`font-black block relative w-[200px] cursor-default`}
												>
													File Type
													{/* <span className='material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]'>swap_vert</span> */}
												</label>
											</div>
											<div className="flex-none px-4 text-center w-[200px]">
												<label
													// onClick={() => handleSort('uploaded_by')}
													className={`font-black block relative w-[200px] cursor-default`}
												>
													Uploaded By
													{/* <span className='material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]'>swap_vert</span> */}
												</label>
											</div>
											<div className="flex-none px-4 text-center w-[200px]">
												<label
													/* onClick={() => handleSort('uploaded_at')} */
													className={`font-black block relative w-[200px] cursor-default`}
												>
													Date Uploaded
													{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
													swap_vert
												</span> */}
												</label>
											</div>
											<div className="flex-none px-4 text-center w-[160px]">
												<label
													/* onClick={() => handleSort('uploaded_at')} */
													className=' font-black block relative w-[160px] cursor-default'
												>
													Failure Reason
													{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
													swap_vert
												</span> */}
												</label>
											</div>
										</div>
										<div className="flex-none w-[150px] pr-2">&nbsp;</div>
									</div>
									{/* Table Body Row - Loop */}

									{renderList()}
								</div>
								<div className="flex justify-center gap-3 pt-2">
									{!invoices_list_hook.hook.isLoading && (
										<Stack spacing={2}>
											<Pagination
												page={parseInt(searchParams.get('invoice_list_page_no'))}
												onChange={(e, value) => {
													// dispatch(dispInvoicePageNo({invoiceListPageNo : value}))
													invoices_list_hook.setArgs((p) => {
														return {
															...p,
															page: value,
															// sort: '',
														};
													});
													searchParams.set('invoice_list_page_no', value)
													setSearchParams(searchParams)
													// setSort({
													// 	type: null,
													// 	order: 'arrow_downward_alt',
													// })
												}}
												count={Math.ceil(
													invoices_list_hook.hook.data?.total_invoices /
													RECORDS_PER_PAGE
												)}
												color="primary"
												variant="outlined"
												shape="rounded"
											/>
										</Stack>
									)}
								</div>
							</div>
						</TabPanel>

						{/* csv code start */}
						<TabPanel>
							<div className="lg:flex gap-4 justify-between items-center vLBG py-4 px-6">
								<div className="flex-none">
									<h1 className="text-lg font-normal text-primary">
										List of <strong className="font-black">CSV Failed Files</strong>
									</h1>
									<p className="text-xs text-gray-500 mb-2">
										List of all CSV Failed Files are given below -
									</p>
								</div>
								<div className="flex gap-3 flex-wrap">
									<div className="flex gap-2 xl:pr-7">
										<button type='button'
											className='bg-red-600 hover:bg-red-500 px-6 h-[40px] rounded-md text-sm font-black text-white flex gap-2 items-center disabled:bg-gray-200 disabled:text-gray-500 disabled:border disabled:border-gray-400'
											disabled={delete_inv_csv_hook.isPending || selectedCsvInvoices.size === 0} onClick={deleteCsvInvClick}>
											<span className="material-icons-outlined text-md">delete</span>
											<span>Delete </span>
										</button>
									</div>
								</div>
							</div>
							<div className="px-6 pt-16 pb-6">
								<div className="listDHeight overflow-auto">
									{/* Table Head */}
									<div className="flex gap-2 mb-2 items-center pr-4">
										<div className="flex-none w-[40px]">&nbsp;
											<Checkbox
												disabled={invoices_csv_list_hook.hook.isFetching}
												checked={selectedCsvInvoices.size === invoices_csv_list_hook.hook?.data?.data?.length && selectedCsvInvoices.size !== 0}
												onChange={(e) => handleCsvCheckAll(e.target.checked)} size="small" />
											<div className="text-[9px] text-center">Select All</div>
										</div>
										<div className="flex-none w-[55px]">
											<div className="flex-none text-center relative w-[55px]">&nbsp;
												{/* <span title='default sort'  className={`text-primary cursor-pointer  material-icons w-4 text-sm mt-1`}>
												swap_vert
											</span> */}
											</div>
										</div>
										<div className="grow px-2 py-1 flex items-center justify-between text-xs">
											<div className="grow pr-4">
												<label
													/* onClick={() => handleSort('fname')} */
													className={` block font-black relative w-[150px] pl-5 cursor-default`}
												>
													Name of File
													{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
													swap_vert
												</span> */}
												</label>
											</div>
											<div className="flex-none px-2 text-center w-[200px]">
												<label
													// onClick={() => handleSort('uploaded_by')}
													className={`font-black block relative w-[200px] cursor-default`}
												>
													File Type
													{/* <span className='material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]'>swap_vert</span> */}
												</label>
											</div>
											<div className="flex-none px-4 text-center w-[200px]">
												<label
													// onClick={() => handleSort('uploaded_by')}
													className={`font-black block relative w-[200px] cursor-default`}
												>
													Uploaded By
													{/* <span className='material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]'>swap_vert</span> */}
												</label>
											</div>
											<div className="flex-none px-4 text-center w-[200px]">
												<label
													/* onClick={() => handleSort('uploaded_at')} */
													className={`font-black block relative w-[200px] cursor-default`}
												>
													Date Uploaded
													{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
													swap_vert
												</span> */}
												</label>
											</div>
											<div className="flex-none px-4 text-center w-[160px]">
												<label
													/* onClick={() => handleSort('uploaded_at')} */
													className=' font-black block relative w-[160px] cursor-default'
												>
													Failure Reason
													{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
													swap_vert
												</span> */}
												</label>
											</div>
										</div>
										<div className="flex-none w-[150px] pr-2">&nbsp;</div>
									</div>
									{/* Table Body Row - Loop */}

									{renderCsvList()}
								</div>
								<div className="flex justify-center gap-3 pt-2">
									{!invoices_csv_list_hook.hook.isLoading && (
										<Stack spacing={2}>
											<Pagination
												page={parseInt(searchParamsCsv.get('invoice_csv_list_page_no'))}
												onChange={(e, value) => {
													// dispatch(dispInvoicePageNo({invoiceListPageNo : value}))
													invoices_csv_list_hook.setArgs((p) => {
														return {
															...p,
															page: value,
															// sort: '',
														};
													});
													searchParamsCsv.set('invoice_csv_list_page_no', value)
													setSearchParamsCsv(searchParamsCsv)
													// setSort({
													// 	type: null,
													// 	order: 'arrow_downward_alt',
													// })
												}}
												count={Math.ceil(
													invoices_csv_list_hook.hook.data?.total_invoices /
													RECORDS_PER_PAGE
												)}
												color="primary"
												variant="outlined"
												shape="rounded"
											/>
										</Stack>
									)}
								</div>
							</div>
						</TabPanel>
					</Tabs>
				</div>

			</div>
		</>
	);
}

function formatDate(inputDateString) {
	if (inputDateString === undefined || inputDateString === null) return '-'
	// Create a Date object from the input date string
	var date = new Date(inputDateString);

	// Extract day, month, and year
	var day = date.getDate();
	var month = date.getMonth() + 1; // Note: getMonth() returns 0-indexed month, so we add 1
	var year = date.getFullYear();

	// Format day and month to have leading zeros if necessary
	day = day < 10 ? '0' + day : day;
	month = month < 10 ? '0' + month : month;

	// Construct the formatted date string
	var formattedDateString = month + '/' + day + '/' + year;

	// Return the formatted date string
	return formattedDateString;
}

export default ListInvoicesIndexByStatus;
