import { useQuery, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { buildAxiosInstance } from '../axiosInstance';
import { LogoutCheck } from '../utils';
import { toast } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
import { useDebouncedState } from '@mantine/hooks';
import {
	BLOB_CONTAINER_NAME,
	RECORDS_PER_PAGE,
} from '../../config';
export const useFetchDashboardData = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'fetch_dasboard_data'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`dashboard?analyst=${body.analyst}&category=${body.category}&client=${body.client}&vendor=${body.vendor}&invoice=${body.invoice}&field=${body.field}&batch=${body.batch}&invoice_status=${body.invoice_status}&datefrom=${body.datefrom}&dateto=${body.dateto}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
				
			}
		},
	});
	 
};

export const useFetchInvoiceNeedAttention = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'fetch_invoice_need_attention'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`invoices_need_attention?analyst=${body.analyst}&category=${body.category}&datefrom=${body.datefrom}&dateto=${body.dateto}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
	 
};


export const useFetchMismatches = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'fetch_mismatches'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`fetch_mismatches?analyst=${body.analyst}&category=${body.category}&datefrom=${body.datefrom}&dateto=${body.dateto}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
	 
};

const fetchClientList = async (Args, dispatch, signal,instance) => {

	try {
		const TOAST_ID = 'get_fetch_client_list'
		const axiosInstance = buildAxiosInstance(TOAST_ID)
		let config = {
			params: {...Args, limit: RECORDS_PER_PAGE},
			signal: signal,
		};
		const responseData = await axiosInstance.get('/client_list',config)
		return responseData
	} catch (error) {
		LogoutCheck(error, dispatch,instance)
		throw new Error(`${error.response.data.message}`);	

	};
};

export const useClientsList_Uploadpage = () => {
	//const Data = useSelector(state => state.auth)
	const { instance } = useMsal();
	let dispatch = useDispatch();
	const [Args, setArgs] = useDebouncedState(
		{
			search: '',
			page:1
		},
		1000
	);
	const hook = useQuery({
		queryKey: ['clientlist_uploadpage', Args],
		queryFn: async ({ signal }) => fetchClientList(Args, dispatch, signal,instance),
		retry: false,
	});
	return {
		hook: hook,
		Args: Args,
		setArgs: setArgs,
	};
};


export const GetVendors = () => {

	const { instance } = useMsal();
	let dispatch = useDispatch();
	return useQuery({
		queryKey: ['get_vendors'],
		retry: false,
		queryFn: async () => {
			try {

				const TOAST_ID = 'get_vendors'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.get('/vendors_dashboard')
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});

};

/** API to fetch batches */
export const useGetBatches = () => {

	const { instance } = useMsal();
	let dispatch = useDispatch();
	return useQuery({
		queryKey: ['get_batches'],
		retry: false,
		queryFn: async () => {
			try {

				const TOAST_ID = 'get_batches'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.get(`batch`)
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

const fetchInvoiceNamesList = async (Args, dispatch, signal,instance) => {

	try {
		const TOAST_ID = 'get_fetch_invoice_names'
		const axiosInstance = buildAxiosInstance(TOAST_ID)
		let config = {
			params: {...Args, limit: RECORDS_PER_PAGE},
			signal: signal,
		};
		const responseData = await axiosInstance.get('/invoice_names',config)
		return responseData
	} catch (error) {
		LogoutCheck(error, dispatch,instance)
		throw new Error(`${error.response.data.message}`);	

	};
};

export const useInvoiceNames = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'invoice_names'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`invoice_names?clientid=${body.ClientId}&categoryid=${body.CategoryId}&VendorId=${body.VendorNameId}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

export const useFieldNames = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'field_names'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`get_field_names?categoryid=${body.CategoryId}&VendorId=${body.VendorNameId}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};