//import React from 'react';
import Notify from './notify';

//import React, { useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
//import UsersListTable from './listTable';
import { useSelector } from 'react-redux';
import { useNotificationListPagewise } from './hooks';
//import { useDebouncedState } from '@mantine/hooks';

function NotificationIndex() {

	//const navigate = useNavigate();

	const navigateRedux = useSelector((state) => state.navigateSlice);

	const user_list_hook = useNotificationListPagewise(navigateRedux.clientListPageNo);

	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				{/* Right Side - White workspaces */}
				<Notify dataUsers={user_list_hook} />
			</div>
		</>
	);
}

export default NotificationIndex;
