import React, { useEffect, useState } from 'react';
import FilterVendorName from './vendor-name';
import FilterCategoryList from './category-list';
import swal from 'sweetalert';

const TopComboNButtons = ({deleteSelected, tryTodelete, global,  Rules, update_rules, setisGlobalFetch, isGlobalFetch,  setRules,  query, setquery, get_rules_hook}) => {
    const [Category, setCategory] = useState(null);
    const [VendorName, setVendorName] = useState(null);
    const [CategoryId, setCategoryId] = useState(null);
    const [VendorNameId, setVendorNameId] = useState(null);
    const [openToCall, setopenToCall] = useState(false)


    useEffect(() => {
        setopenToCall(VendorNameId !== null && CategoryId !==null)
        setquery({
            vendor_alias_id : VendorNameId, 
            category_id: CategoryId
        })
        setRules(null)
        setisGlobalFetch(false)
      
    }, [VendorNameId , CategoryId])

    function getRules() {
        get_rules_hook.mutate(query)
    }

        function handleCancle(){
                swal('Are you sure you want to Cancle the changes ?', {
                    buttons: ['No', 'Yes'],
                }).then((value) => {
                    if (value) {
                        // User clicked "Yes" button
                        let arr = get_rules_hook.data?.data.rules
                        if (arr){
                            arr.sort((a, b) => a.entity_name.localeCompare(b.entity_name));
                            setRules(arr.map(itm => {
                                itm.checked = false
                                return itm
                            }))
                        }
                    }
                });
            
        }

        async function saveChanges() {

            swal('Are you sure you want to Save the changes ?', {
                buttons: ['No', 'Yes'],
            }).then(async (value) => {
                if (value) {
                    let rules = [...Rules].map(itm => {
                        return { 
                            entity_name : itm.entity_name , 
                            entity_type: itm.entity_type , 
                            entity_rule : itm.entity_rule
                        }
                    })
                    if (global) { 
                        
                        let data = { 
                            rules: rules,
                            global : true
                        }
                
                        await update_rules.mutateAsync(data)
                        get_rules_hook.mutate({ global : true })
                    }
                    else{
                        let data = { 
                            vendor_alias_id : query?.vendor_alias_id,
                            category_id : query.category_id,
                            rules: rules
                        }
                
                        await update_rules.mutateAsync(data)
                        getRules()
        
                    }
            
                }
            });



            
        }
    

    

    return (
        <>
            <div className='flex justify-end gap-4'>
                {!global &&<div className='grow flex gap-3'>
                    <div className='w-1/2'>
                        <FilterVendorName
                            setVendorName={setVendorName}
                            VendorName={VendorName}
                            setVendorNameId={setVendorNameId}
                            VendorNameId={VendorNameId}
                            required='true'
                        />
                    </div>
                    <div className='w-1/2'>

                        <FilterCategoryList
                            setCategory={setCategory}
                            Category={Category}
                            setCategoryId={setCategoryId}
                            CategoryId={CategoryId}
                        />
                    </div>
                </div>}
                <div className='flex gap-3 justify-end pt-4 lg:pt-0'>
           
                    {!global && <button disabled={!openToCall || get_rules_hook.isPending || update_rules.isPending } onClick={getRules} type="button" class="bg-blue-200 h-[40px] hover:bg-indigo-400 px-4 py-1 rounded-md text-primary text-sm hover:text-white flex items-center font-black disabled:bg-gray-200 disabled:text-gray-500 disabled:hover:text-gray-500">
                        <span class="material-icons text-sm mr-1">add</span>Get Rules
                    </button> }
                        <button disabled={!Rules || tryTodelete || update_rules.isPending} onClick={() => deleteSelected()} type="button" className="bg-red-100 h-[40px] px-4 py-1 flex gap-2 items-center text-center text-red-700 text-sm rounded-md font-black disabled:bg-gray-300 disabled:text-gray-900">
                            <span className="material-icons text-sm">delete</span>Delete
                        </button>
                    
                    
                    <button disabled={!Rules} onClick={handleCancle} type="button" className="bg-red-100 px-4 h-[40px] py-1 flex gap-2 items-center text-center text-red-700 text-sm rounded-md font-black disabled:bg-gray-300 disabled:text-gray-900">
                        <span class="material-icons text-sm">cancel</span>Reset
                    </button>
                    <button disabled={ (!global && (!openToCall || query?.vendor_alias_id ==null || query?.category_id == null ) ) || update_rules.isPending || tryTodelete || get_rules_hook.isPending || !Rules } onClick={saveChanges} class="bg-blue-800 hover:bg-indigo-700 flex items-center px-4 h-[40px] text-sm font-black rounded-md text-white  disabled:bg-gray-300 disabled:text-gray-900">
                        <span class="material-icons text-sm mr-1">check</span>Save
                    </button>
                    {/* <button type="button" class="bg-blue-200 hover:bg-indigo-400 px-4 py-1 rounded-md text-primary text-sm hover:text-white flex items-center font-black disabled:bg-gray-200 disabled:text-gray-500 disabled:hover:text-gray-500">
                        <span class="material-icons text-sm mr-1">add</span>Add Column
                    </button>
                    <button type="button" class="bg-red-100 px-4 py-1 flex gap-2 items-center text-center text-red-700 text-sm rounded-md font-black disabled:bg-gray-300">
                        <span class="material-icons text-sm">delete</span>Delete
                    </button> */}
                </div>
            </div>
            {!global && isGlobalFetch && <div className='py-2 font-bold'>Found Global Rules</div>}
        </>
    )
}

export default TopComboNButtons
