import { Outlet, useSearchParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import React, { useState, useCallback, useEffect } from 'react';
//import { useParams } from 'react-router-dom'
import { useMismatcheslistPagewise, useSoftDeleteFailedFiles } from './hooks';
//import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
//import Tooltip from '@mui/material/Tooltip';
import { RECORDS_PER_PAGE } from '../../config';
//import { dispInvoicePageNo } from '../navigateSlice';
import LoaderFull from '../shared/loader-full-screen';
import { useDebouncedState } from '@mantine/hooks';
import { decodeName, } from '../utils';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//import { useGetSasToken } from './hooks';
//import {  BLOB_URL } from '../../config';
import { handleSearchKeys, handleSortUtil } from '../utils';

function ListMismatchesIndexByStatus() {
	//const navigateRedux = useSelector(state => state.navigateSlice)
	const [searchParams, setSearchParams] = useSearchParams();

	const delete_inv_hook = useSoftDeleteFailedFiles()
	//let dispatch = useDispatch()

	const [selectedMismatches, setselectedMismatches] = useState(new Set([]))
	const [selectedInvoices, setselectedInvoices] = useState(new Set([]))

	const [DebounceSearch] = useDebouncedState('', 500);
	let sortArg = ''
	if (searchParams.get('invoice_list_sort_key') === '' || searchParams.get('invoice_list_sort_key') === null) {
		sortArg = ''
	}
	else {
		sortArg = [searchParams.get('invoice_list_sort_key'), searchParams.get('invoice_list_sort_order')].join(' ')
	}
	const mismatches_list_hook = useMismatcheslistPagewise(
		decodeName(searchParams.get('client_id')),
		decodeName(searchParams.get('vendor_id')),
		decodeName(searchParams.get('category_name')),
		(parseInt(searchParams.get('mismatches_list_page_no')) || 1),
		sortArg
	)

	const renderList = useCallback(() => {
		if (mismatches_list_hook.hook.isFetching || delete_inv_hook.isPending) {
			return <LoaderFull />;
		} else if (mismatches_list_hook.hook.data?.data?.length === 0) {
			return <p className="text-center">No data found</p>;
		} else {
			return (
				<Outlet
					context={{
						mismatchList: mismatches_list_hook.hook.data,
						selectedInvoices: selectedInvoices,
						setselectedInvoices: setselectedInvoices,
						selectedMismatches: selectedMismatches,
						setselectedMismatches: setselectedMismatches
					}}
				/>
			);
		}
	}, [mismatches_list_hook.hook, selectedInvoices, setselectedInvoices, selectedMismatches, delete_inv_hook.isPending]);

	function handleCheckAll(checked) {
		if (checked) {
			setselectedInvoices(new Set(mismatches_list_hook.hook?.data?.data.map(itm => itm._id)))
			setselectedMismatches(new Set(mismatches_list_hook.hook?.data?.data.map(itm => itm.invoice_id)))
		}
		else {
			setselectedInvoices(new Set([]))
			setselectedMismatches(new Set([]))
		}
	}

	/* function deleteInvClick() {

		swal('Are you sure you want to delete this? This action is permanent and cannot be undone.', {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				// User clicked "Yes" button
				let body = {
					document_ids: Array.from(selectedMismatches)
				}
				delete_inv_hook.mutate(body)
			} else {
				// User clicked "No" button or closed the dialog
			}
		});
	} */

	/* useEffect(() => {
		if (delete_inv_hook.isSuccess) {
			toast.success("The failed files has been deleted")
			setselectedMismatches(new Set([]))
			mismatches_list_hook.hook.refetch()
		}
	}, [delete_inv_hook.isSuccess]) */

	/* const SORT_KEY_CONST = 'mismatch_list_sort_key'
	const SORT_ORDER_CONST = 'mismatch_list_sort_order'
	const PAGE_NO_CONST = 'mismatch_list_page_no'
	const [Args, setArgs] = useState({}) */

	const [Sort, setSort] = useState({
		type: null,
		order: 'arrow_downward_alt',
	});
	function handleSort(typer) {
		setSort((p) => {
			if (p.type === typer) {
				if (p.order === 'arrow_upward_alt') {
					return {
						type: typer,
						order: 'arrow_downward_alt',
					};
				} else {
					return {
						type: typer,
						order: 'arrow_upward_alt',
					};
				}
			} else {
				return {
					type: typer,
					order: 'arrow_downward_alt',
				};
			}
		});

	}

	useEffect(() => {
		if (Sort.type !== null) {
			mismatches_list_hook.setArgs((p) => {
				var tp1 = Sort.order === 'arrow_downward_alt' ? 'asc' : 'desc';
				var tp = Sort.type + ' ' + tp1;
				return {
					...p,
					sort: tp,
				};
			});
		}
	}, [Sort.type, Sort.order]);

	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter manageFlds">

				<div className="relative fldWrap">

					<div className="lg:flex gap-4 justify-between items-center vLBG py-4 px-6">
						<div className="flex-none">
							<h1 className="text-lg font-normal text-primary">
								List of <strong className="font-black">Mismatches</strong>
							</h1>
							<p className="text-xs text-gray-500 mb-2">
								List of all fields that require mismatch intervention -
							</p>
						</div>
						<div className="flex gap-3 flex-wrap">
							<div className="flex gap-2 xl:pr-7">
								{/* <button type='button'
									className='bg-red-600 hover:bg-red-500 px-6 h-[40px] rounded-md text-sm font-black text-white flex gap-2 items-center disabled:bg-gray-200 disabled:text-gray-500 disabled:border disabled:border-gray-400'
									disabled={delete_inv_hook.isPending || selectedMismatches.size === 0} onClick={deleteInvClick}>
									<span className="material-icons-outlined text-md">delete</span>
									<span>Delete </span>
								</button> */}
							</div>
						</div>
					</div>
					<div className="px-6 pt-8 pb-6">
						<div className="listDHeight larger overflow-auto">
							{/* Table Head */}
							<div className="flex gap-2 mb-2 items-center pr-4">
								<div className="flex-none w-[40px]">&nbsp;
									{/* <Checkbox
										disabled={mismatches_list_hook.hook.isFetching}
										checked={selectedMismatches.size === mismatches_list_hook.hook?.data?.data?.length && selectedMismatches.size !== 0}
										onChange={(e) => handleCheckAll(e.target.checked)} size="small" />
									<div className="text-[9px] text-center">Select All</div> */}
								</div>

								<div className="grow px-5 py-1 text-xs flex items-center justify-between">

									<div className="flex-none text-center pr-4 w-[180px]">
										<label
											onClick={() => handleSort('client_name')}
											className={`${Sort.type === 'client_name' ? 'text-primary' : ''} font-black block w-[110px] relative cursor-pointer`}
										>
											Client Name
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[140px]">
										<label
											onClick={() => handleSort('vendor_name')}
											className={`${Sort.type === 'vendor_name' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
										>
											Vendor Name
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[180px]">
										<label
											onClick={() => handleSort('expense_area')}
											className={`${Sort.type === 'expense_area' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
										>
											Category
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[180px]">
										<label
											// onClick={() => handleSort('invoice_name')}
											className={`${Sort.type === 'invoice_name' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
										>
											Invoice Name

										</label>
									</div>
									<div className="flex-none px-4 text-center w-[140px]">
										<label
											onClick={() => handleSort('invoice_date')}
											className={`${Sort.type === 'invoice_date' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
										>
											Invoice Date
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[180px]">
										<label
											// onClick={() => handleSort('description')}
											className={`${Sort.type === 'description' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
										>
											Description
											{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span> */}
										</label>
									</div>

									<div className="flex-none px-4 text-center w-[180px]">
										<label

											className={`${Sort.type === 'mismatch_explanation' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
										>
											Mismatch Explanation

										</label>
									</div>

								</div>

								<div className="flex-none w-[100px] flex gap-2 pr-2 text-xs justify-center items-center h-6">
									<label className='font-black block'>
										Link to
									</label>
								</div>
							</div>
							{/* Table Body Row - Loop */}

							{renderList()}
						</div>
						<div className="flex justify-center gap-3 pt-2">
							{!mismatches_list_hook.hook.isLoading && (
								<Stack spacing={2}>
									<Pagination
										page={parseInt(searchParams.get('mismatches_list_page_no'))}
										onChange={(e, value) => {
											// dispatch(dispInvoicePageNo({invoiceListPageNo : value}))
											mismatches_list_hook.setArgs((p) => {
												return {
													...p,
													page: value,
													// sort: '',
												};
											});
											searchParams.set('mismatches_list_page_no', value)
											setSearchParams(searchParams)
											// setSort({
											// 	type: null,
											// 	order: 'arrow_downward_alt',
											// })
										}}
										count={Math.ceil(
											mismatches_list_hook.hook.data?.total_mismatch_count /
											RECORDS_PER_PAGE
										)}
										color="primary"
										variant="outlined"
										shape="rounded"
									/>
								</Stack>
							)}
						</div>
					</div>

				</div>

			</div>
		</>
	);
}

function formatDate(inputDateString) {
	if (inputDateString === undefined || inputDateString === null) return '-'
	// Create a Date object from the input date string
	var date = new Date(inputDateString);

	// Extract day, month, and year
	var day = date.getDate();
	var month = date.getMonth() + 1; // Note: getMonth() returns 0-indexed month, so we add 1
	var year = date.getFullYear();

	// Format day and month to have leading zeros if necessary
	day = day < 10 ? '0' + day : day;
	month = month < 10 ? '0' + month : month;

	// Construct the formatted date string
	var formattedDateString = month + '/' + day + '/' + year;

	// Return the formatted date string
	return formattedDateString;
}

export default ListMismatchesIndexByStatus;
