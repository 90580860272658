import React, { useState, useEffect } from 'react';

import { useClientsList_Uploadpage } from './hooks';
//import { toast } from 'react-toastify'
import { Autocomplete, TextField } from '@mui/material';
//import Box from '@mui/material/Box';
//import Grid from '@mui/material/Grid';
//import Typography from '@mui/material/Typography';

//import parse from 'autosuggest-highlight/parse';

function FltrClientName({ setClientName, ClientName, setClientId, ClientId }) {
	const [options, setoptions] = useState([]);
	const client_list_hook = useClientsList_Uploadpage();
	const [Focus, setFocus] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [open, setOpen] = useState(false);
	const [firstTime, setfirstTime] = useState(true);

	useEffect(() => {
		if (client_list_hook.hook.data) {
			setfirstTime(false);
			//console.log(client_list_hook.hook.data.data);

			setoptions(client_list_hook.hook.data.data);
		}
	}, [client_list_hook.hook.data, client_list_hook.hook.isSuccess]);

	useEffect(() => {
		client_list_hook.setArgs((args) => {
			return {
				...args,
				search: inputValue,
			};
		});
	}, [client_list_hook, inputValue]);

	let stop = false;

	return (
		<>
			<div className="grow missDD">
				<div className="lightT">
					{firstTime && client_list_hook.hook.isPending ? (
						<p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
							<span className="text-sm text-slate-400 ps-4 inline-block">
								Loading...
							</span>
						</p>
					) : (
						<Autocomplete
							disablePortal
							id="google-map-demo"
							className='expCat'
							clearOnBlur={true}
							getOptionLabel={(option) =>
								typeof option === 'string' ? option : option.name
							}
							isOptionEqualToValue={(option, value) => true}
							filterOptions={(x) => x}
							options={options}
							autoComplete
							includeInputInList
							filterSelectedOptions
							value={ClientName}
							open={open}
							onOpen={() => {
								setOpen(true);
							}}
							onClose={() => {
								setOpen(false);
							}}
							noOptionsText="No Client Match"
							onChange={(event, newValue) => {
								if (newValue) {
									setClientName(newValue?.name);
									setClientId(newValue?._id);
								} else {
									setClientName(null);
									setClientId(null);
								}
							}}
							onInputChange={(event, newInputValue) => {
								setInputValue(newInputValue);
							}}
							renderInput={(params) => (
								<TextField {...params} label="Client Name" fullWidth />
							)}
							onFocus={() => setFocus(true)}
							onBlur={() => setFocus(false)}
							renderOption={(props, option) => {
								if (stop) return;
								if (client_list_hook.hook.isPending) {
									// Stop further iteration if stop flag is true
									stop = true;
									return (
										<p key={'loading_client'} className="ml-2">
											Loading...
										</p>
									);
								}

								return (
									<li key={option._id} {...props}>
										{option.name}
									</li>
								);
							}}
						/>
					)}
					{/* {(ClientName == null || ClientName === '') && Focus && (
						<small className="text-red-600 ">Client name is required</small>
					)} */}
				</div>
			</div>
		</>
	);
}

export default FltrClientName;
