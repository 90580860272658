import React, { useState, useEffect } from 'react';
//import { toast } from 'react-toastify'

//import { Autocomplete, TextField } from '@mui/material';
import { Autocomplete, Box, TextField } from '@mui/material'

import { useFieldNames } from './hooks';

function FilterFieldName({
    setFieldName,
    FieldName,
    setFieldNameId,
    FieldNameId,
    ClientId,
    CategoryId,
    VendorNameId
}) {
    //console.log(ClientId, CategoryId, VendorNameId);
    
    const [options, setoptions] = useState([]);
    const client_list_hook = useFieldNames();
    
    const [Focus, setFocus] = useState(false);

    useEffect(() => {
        client_list_hook.mutate({CategoryId, VendorNameId});
	}, [CategoryId,VendorNameId]);

	useEffect(() => {
        //console.log(client_list_hook.data?.data);
		if (client_list_hook.data?.data?.rules?.length>0) {
			setoptions(client_list_hook.data.data?.rules?.map((itm) => itm));
		}
	}, [client_list_hook.data,CategoryId,VendorNameId]);

    function changeProjectType(val) {
        //console.log(val);
        
        setFieldName(val);
        let obj = client_list_hook.data.data.rules.find((o) => o.entity_name === val?.entity_name);
        //console.log(obj);
        
        if (obj) {
            setFieldNameId(obj.entity_name);
        }
        else {
            setFieldNameId(null)
            setFieldName(null)
        }
    }

    return (
        <>
            <div className="grow missDD">
                <div className="lightT">

                    {client_list_hook.fetchStatus === 'fetching' ? (
                        <p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
                            <span className="text-sm text-slate-400 ps-4 inline-block">
                                Loading...
                            </span>
                        </p>
                    ) : (
                        <Autocomplete
                            disablePortal
                            isOptionEqualToValue={(option, value) =>
                                option === value || value === '' || value == null
                            }
                            id="combo-box-demo"
                            className='expCat'
                            options={options}
                            value={FieldName}
                            getOptionLabel={(option) => option.entity_name}
                            renderOption={(props, option) => {
                                return (
                                    <Box component="li" {...props} key={option._id}>
                                        {option.entity_name}
                                    </Box>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Field Name" />
                            )}
                            onChange={(event, value) => changeProjectType(value)}
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                            // onInputChange={(event, newInputValue) => {
                            //     setProjectType(newInputValue);
                            //   }}
                        />
                    )}
                    {/* {(FieldName == null || FieldName === '') && Focus && (
                        <small className="text-red-600 ">Field name is required</small>
                    )} */}
                </div>
            </div>
        </>
    );
}

export default FilterFieldName;
