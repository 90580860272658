import React, { useState, useEffect } from 'react';

import { Autocomplete, TextField } from '@mui/material';
import { useGetBatches } from './hooks';

function FilterBatchName({
    setBatchName,
    BatchName,
    setBatchNameId,
    BatchNameId
}) {

    const [lable] = useState('Batch Name')
    const [open, setOpen] = useState(false);
    const [options, setoptions] = useState([]);
    const batch_list_hook = useGetBatches();
    //const { refetch, isFetching } = useGetBatches();
    const [Focus, setFocus] = useState(false);

    useEffect(() => {
        if (batch_list_hook.data) {
            //const currentBatches = batch_list_hook.data.data;
            //const timestamp = formatDateToCustomString();
            ///const newBatch = `${ClientName} / ${Category} / ${ProjectType} / ${timestamp}`;
            // Create an array with the new batch at the start and existing batches after it
            //const updatedData = [{ _id: null, name: newBatch }, ...currentBatches];

            // Update the state with the new batch list
            setoptions(batch_list_hook.data.data);
        }
    }, [batch_list_hook.data, batch_list_hook.isSuccess]);

    // useEffect(() => {
    //     if(refetchBatch){
    //         //Refetch batches here
    //         refetch();
    //     }

    // },[refetchBatch,refetch])

    function changeBatch(val) {
        console.log(val);
        
        setBatchName(val);
        let obj = batch_list_hook.data.data?.find((o) => (o?.name === val?.name));
        console.log(obj);
        
        if (obj) {
            setBatchNameId(obj._id);
        }
        else {
            setBatchNameId(null)
            setBatchName(null)
        }
    }
    let stop = false;
    return (
        <>
            <div className="grow missDD">
                <div className="lightT" id="sbatch">
                    {batch_list_hook.isPending ? (
                        <p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
                            <span className="text-sm text-slate-400 ps-4 inline-block">
                                Loading...
                            </span>
                        </p>
                    ) : (
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            className='expCat'
                            clearOnBlur={true}
                            getOptionLabel={(option) =>
                                typeof option === 'string' ? option : option.name
                            }
                            isOptionEqualToValue={(option, value) => true}
                            options={options}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={BatchName}
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            noOptionsText="No Batch Match"
                            renderInput={(params) => (
                                <TextField {...params} label={lable} />
                            )}
                            onChange={(event, value) => changeBatch(value)}
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                            renderOption={(props, option) => {
                                
                                return (
                                    <li key={option._id} {...props}>
                                        {option.name}
                                    </li>
                                );
                            }}

                        />
                    )}
                    {/* {BatchName == null && Focus && (
                        <small className="text-red-600 ">
                            Batch name is required
                        </small>
                    )} */}
                </div>
            </div>
        </>
    );
}

export default FilterBatchName;
