import styles from '../shared/common.module.css';
//import Checkbox from '@mui/material/Checkbox';

import React, { useCallback, useEffect } from 'react';
import LoaderFull from '../shared/loader-full-screen';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
/* import { useUserListPagewise } from './hooks'
import { useSearchParams } from "react-router-dom"
import { dispClientPage, dispClientPageNo, dispInvoiceId, dispInvoicePageNo, dispVendorId, dispVendorPageNo } from '../navigateSlice' */
import { RECORDS_PER_PAGE } from '../../config';
//import { TextHighlighter } from '../shared/textBold';
//import { useNavigate } from 'react-router-dom';
//import swal from 'sweetalert';
import { useReadNotification } from './hooks';
import { toast } from 'react-toastify';
import { useOutletContext } from 'react-router-dom';

function Notify(props) {
	const {getNotifications} = useOutletContext();
	const datausers = props.dataUsers;
	const Search = props.Search;
	//const navigate = useNavigate();

	const PrivilagesList = useSelector(
		(state) => state?.privilageSlice?.allowedActions
	);

	/* const [Sort, setSort] = useState({
		type: null,
		order: 'arrow_downward_alt',
	}); */

	/* function handleSort(typer) {
		setSort((p) => {
			if (p.type === typer) {
				if (p.order === 'arrow_upward_alt') {
					return {
						type: typer,
						order: 'arrow_downward_alt',
					};
				} else {
					return {
						type: typer,
						order: 'arrow_upward_alt',
					};
				}
			} else {
				return {
					type: typer,
					order: 'arrow_downward_alt',
				};
			}
		});
	} */

	const delete_user_hook = useReadNotification(null);

	function handleDelete(e, id) {
		e.preventDefault();
		//console.log(id);
		
		delete_user_hook.mutate(id);
		
	}

	useEffect(() => {
		if (delete_user_hook.isError) {
			toast.error(delete_user_hook.error?.message);
		} else if (delete_user_hook.isSuccess) {
			toast.success(delete_user_hook.data?.message);
			datausers.hook.refetch();
			getNotifications();
		}
	}, [
		delete_user_hook.isError,
		delete_user_hook.isSuccess,
		delete_user_hook.error?.message
	]);

	/* useEffect(() => {
		if (Sort.type !== null) {
			datausers.setArgs((p) => {
				var tp1 = Sort.order === 'arrow_downward_alt' ? 'asc' : 'desc';
				var tp = Sort.type + ' ' + tp1;
				return {
					...p,
					sort: tp,
				};
			});
		}
	}, [Sort.type, Sort.order]); */

	const renderList = useCallback(() => {
		if (datausers.hook.isFetching) {
			return <LoaderFull />;
		} else if (datausers.hook.data?.data?.length === 0) {
			return <p className="text-center">No data found</p>;
		} else {
			return (
				<>
					{datausers.hook?.data?.data.map((user, ind) => (

						<div key={`itm` + ind} className='flex gap-4 items-center mb-4'>
							{/* <div className='flex-none w-6'>
								<Checkbox size="small" />
							</div> */}
							<div className='grow border border-gray-300 rounded-md px-6 py-3 text-sm'>
								<div className='flex gap-4 items-center justify-between'>
									<div className='grow'>
										{!user.read &&
											<p><strong>{user.message}</strong></p>
										}

										{user.read &&
											<p>{user.message}</p>
										}

										{/* <p>{user.message}</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur...</p> */}
									</div>
									<div className='flex-none w-32 flex flex-col justify-end items-end gap-1'>
									{!user.read && <div>
											<span title="Mark as read" onClick={(event) => {
												handleDelete(event, user._id);
											}} className="material-icons-outlined text-gray-900 text-md">done_all</span>
										</div> }
										<div className='text-xs text-gray-400'>{formatDate(user.created_at)}</div>
									</div>
								</div>
							</div>
						</div>


					))}
				</>
			);
		}
	}, [datausers.hook.isFetching, datausers.hook.data?.data, Search, PrivilagesList]);

	return (
		<>
			<div className="lg:flex gap-4 justify-between items-center vLBG py-4 px-6">
				<div className='flex flex-col items-start justify-center'>
					<h1 className="text-lg font-black text-primary">Notifications</h1>
				</div>
				<div className="flex gap-3 flex-wrap">
					<div className="flex gap-2">
						<div className="relative">
							<span className="material-icons absolute top-2 left-2 z-10 text-slate-400 text-md">
								search
							</span>
							<input
								type="text"
								// onChange={(e) => {
								//     setDebounceSearch(() => {
								//         setArgs((p) => {
								//             return { ...p, search: e.target.value, page: 1, sort: '' };
								//         });
								//         return e.target.value
								//     });
								// }}
								className="border relative border-slate-300 rounded-md w-full h-10 py-2 pr-2 pl-10 focus:outline-blue-400 text-sm"
								placeholder="Search..."
							// defaultValue={Args.search}
							/>
						</div>
					</div>
					<div>
						<button className="bg-blue-800 hover:bg-indigo-700 flex items-center px-4 h-[40px] text-sm font-black rounded-md text-white">
							<span className="material-icons text-white text-sm mr-1">
								refresh
							</span>
							Reload
						</button>
					</div>
				</div>
			</div>

			{/* === notifications panel === */}

			<div className={`${styles.notifyPanel} px-6 py-4 overflow-auto`}>
				{/* <div className='flex gap-4 mb-4'>
					<div className='flex-none w-6'>
						<Checkbox size="small" />
					</div>
					<div>
						<h4>Notifications</h4>
					</div>
				</div> */}

				{/* === Loop of notifications === */}

				{renderList()}


			</div>
			<div className="flex justify-center pt-2">
				{!datausers.hook.isLoading && (
					<Stack spacing={2}>
						<Pagination
							defaultPage={datausers.Args.page}
							onChange={(e, value) => {
								// dispatch(dispClientPageNo({ clientListPageNo: value }))
								datausers.setArgs((p) => {
									return {
										...p,
										page: value,
										// sort: '',
									};
								});
								// setSort({
								// 	type: null,
								// 	order: 'arrow_downward_alt',
								// })
							}}
							count={Math.ceil(
								datausers.hook.data?.total / RECORDS_PER_PAGE
							)}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Stack>
				)}
			</div>

		</>
	);
}

function formatDate(inputDateString) {
	//console.log(inputDateString);

	if (!inputDateString) return '-'
	// Create a Date object from the input date string
	var date = new Date(inputDateString);

	// Extract day, month, and year
	var day = date.getDate();
	var month = date.getMonth() + 1; // Note: getMonth() returns 0-indexed month, so we add 1
	var year = date.getFullYear();

	// Format day and month to have leading zeros if necessary
	day = day < 10 ? '0' + day : day;
	month = month < 10 ? '0' + month : month;

	// Construct the formatted date string
	var formattedDateString = month + '/' + day + '/' + year;

	// Return the formatted date string
	return formattedDateString;
}

export default Notify;