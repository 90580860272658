import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


import GlobalFieldsManageIndex from './globalFields';
import CategoryFieldsManageIndex from './customFields';

const ManageValidateIndex = () => {
  return (
    <div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
      <div className="px-6 pt-4">
        <Tabs>
          <TabList>
            <Tab>Global Fields Configuration</Tab>
            <Tab>Category Fields Configuration</Tab>
          </TabList>

          <TabPanel>
            <div className='pt-2'>
            <GlobalFieldsManageIndex />
              
            </div>
          </TabPanel>
         
          <TabPanel>
            <div className='pt-2'>
            <CategoryFieldsManageIndex />
            </div>
          </TabPanel>         
        </Tabs>
      </div>
    </div>
  )
}

export default ManageValidateIndex
