import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoaderFull from '../shared/loader-full-screen';
import { useClientListPagewise } from './hooks';
import { Outlet, useSearchParams } from 'react-router-dom';
//import { PopupAddClient } from './popupAddClient';
//import { useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { RECORDS_PER_PAGE } from '../../config';
import { useDebouncedState } from '@mantine/hooks';
import { handleSearchKeys, handleSortUtil } from '../utils';
const SORT_KEY_CONST = 'client_list_sort_key'
const SORT_ORDER_CONST = 'client_list_sort_order'
const PAGE_NO_CONST = 'client_list_page_no'

function ListClientsIndex() {
	const [DebounceSearch, setDebounceSearch] = useDebouncedState('', 500);
	//const PrivilagesList = useSelector((state) => state?.privilageSlice?.allowedActions)
	const [searchParams, setSearchParams] = useSearchParams();
	const isFirstRender = useRef(true)


	const [Sort, setSort] = useState(handleSearchKeys(searchParams,SORT_KEY_CONST,SORT_ORDER_CONST,PAGE_NO_CONST));	
	const {hook: client_list_hook ,Args,setArgs } = useClientListPagewise(Sort.page,  Sort.sort)

	//const [IsOpened, setIsOpened] = useState(false);

	function handleSort(typer) {
		let {sort_key ,sort_order} = handleSortUtil(typer , Sort)
		searchParams.set(SORT_KEY_CONST, sort_key)
		searchParams.set(SORT_ORDER_CONST, sort_order)
		setSearchParams(searchParams)

	}	

	useEffect(() => {		
		if (!isFirstRender.current) {
			let newSort = handleSearchKeys(searchParams, SORT_KEY_CONST, SORT_ORDER_CONST, PAGE_NO_CONST)			
			setSort(newSort)
			setArgs((p) => {
				return {
					...p,
					...newSort
				}
			})
		}
		isFirstRender.current = false


	}, [searchParams, setArgs])
	


	const renderList = useCallback(() => {
		if (client_list_hook.isFetching) {
			return <LoaderFull />;
		} else if (client_list_hook.data?.data?.length === 0) {
			return <p className="text-center">No data found</p>;
		} else {
			return (
				<Outlet
					context={{
						clientList: client_list_hook.data,
						Search: DebounceSearch,
					}}
				/>
			);
		}
	}, [client_list_hook.isFetching , client_list_hook.data, DebounceSearch ]);
	

	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				<>
					<div className="lg:flex gap-4 justify-between items-center vLBG py-4 px-6">
						<div>
							<h1 className="text-lg font-normal text-primary">
								List of <strong className="font-black">Clients</strong>
							</h1>
							<p className="text-xs text-gray-500 mb-2">
								List of all your clients is given below -
							</p>
						</div>
						<div className="flex gap-3 flex-wrap">
							<div className="flex gap-2">
								<div className="relative">
									<span className="material-icons absolute top-2 left-2 z-10 text-slate-400 text-md">
										search
									</span>
									<input
										type="text"
										onChange={(e) => {
											setDebounceSearch(()=> {
												setArgs((p) => {
													return { ...p, search: e.target.value, page: 1, sort: '' };
												});
												return e.target.value
											});
										}}
										className="border relative border-slate-300 rounded-md w-full h-10 py-2 pr-2 pl-10 focus:outline-blue-400 text-sm"
										placeholder="Search..."
										defaultValue={Args.search}
									/>
								</div>
							</div>
							{/* <div>
								{PrivilagesList.includes('add_client') && (
									<button
										onClick={() => setIsOpened(true)}
										className="bg-blue-800 hover:bg-indigo-700 flex items-center px-4 h-[40px] text-sm font-black rounded-md text-white"
									>
										<span className="material-icons text-white text-sm mr-1">
											add
										</span>
										Add New Client
									</button>
								)}
							</div> */}
						</div>
					</div>
					<div className="p-6">
						<div className="listDHeight overflow-auto">
							<div className="flex gap-2 mb-2">
								<div className="flex-none text-center relative w-[55px]">
								<span title='default sort' onClick={() => handleSort('')} className={`${Sort.type === '' ? 'text-primary' : 'cursor-pointer'}   material-icons w-4 text-sm mt-1`}>										swap_vert
									</span>
								</div>
								<div className="grow px-5 py-1 text-xs flex items-center justify-between">
									<div className="grow pr-4">
										<label
											onClick={() => handleSort('name')}
											className={`${Sort.type === 'name' ? 'text-primary' : ''} font-black block w-[110px] relative cursor-pointer`}
										>
											Name of Client
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[140px]">
										<label
											onClick={() => handleSort('total_vendors')}
											className={`${Sort.type === 'total_vendors' ? 'text-primary' : ''} font-black block relative cursor-pointer`}
										>
											Total Vendors
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[140px]">
										<label
											onClick={() => handleSort('total_invoices')}
											className={`${Sort.type === 'total_invoices' ? 'text-primary' : ''} font-black block relative cursor-pointer`}
										>
											Total Invoices
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[155px]">
										<label
											onClick={() => handleSort('total_contracts')}
											className={`${Sort.type === 'total_contracts' ? 'text-primary' : ''} font-black block relative cursor-pointer`}
										>
											Total Contracts
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
								</div>
								<div className="flex-none w-[141px] pr-2">&nbsp;</div>
							</div>
							{renderList()}
							
						</div>

						<div className="flex justify-center pt-2">
							
							{!client_list_hook.isLoading && (
								<Stack spacing={2}>
									<Pagination
										page={Sort.page}
										onChange={(e, value) => {
											searchParams.set(PAGE_NO_CONST, value)
											setSearchParams(searchParams)
											
										}}
										count={Math.ceil(
											client_list_hook.data?.total_clients /
												RECORDS_PER_PAGE
										)}
										color="primary"
										variant="outlined"
										shape="rounded"
									/>
								</Stack>
							)}
						</div>
					</div>
				</>
				{/* {IsOpened && (
					<PopupAddClient
						isOpened={IsOpened}
						setIsOpened={setIsOpened}
					/>
				)} */}
			</div>
		</>
	);
}

export default ListClientsIndex;
