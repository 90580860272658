import React, { Fragment, useState } from 'react';
import styles from '../invoice.module.css';
// import { PopUpAddColName } from './popUpAddCol';
import swal from 'sweetalert';
import { TableDropdown } from './dropdown';


function TableList({IsCSV, tablesExtracted, settablesExtracted, setBoxes, setchangeDetect, setchangeAdd, DocLockedForMe , MismatchIds , PageNo, gotoMismatch}) {
	/* const [column, setColumn] = React.useState('');
	const handleChange = (event) => {
		setColumn(event.target.value);
	}; */
	// const [IsOpened, setIsOpened] = useState(false);
	// const [tb, settb] = useState(null);
	const [isDeleteEnable, setisDeleteEnable] = useState({})

	function DeleteColPop(tableName, col) {
		swal('Are you sure you want to Delete this column?', {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				// setBoxes(p=> {
				// 	let tpbx = [...p].filter(bx => !(bx.table ===tableName && bx.entity ===col) )
				// 	return tpbx
				// })
				settablesExtracted(prev => {
					let tempTables = { ...prev }
					Object.keys(tempTables[tableName]).map(row => {
						let newrow = tempTables[tableName][row].filter(obj => obj.entity !== col)
						tempTables[tableName][row] = newrow
					return null
					})
					return tempTables
				})
				if (DocLockedForMe){
					setchangeDetect(true)
					setchangeAdd(p=>p+1)
				}
			} else {
				// User clicked "No" button or closed the dialog
				// Add any additional logic you want when the user clicks "No" or closes the dialog
			}
		});
	}

	function DeleteRowPop(tableName, rowName) {
		swal('Are you sure you want to Delete this row?', {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				// setBoxes(p=> {
				// 	let tpbx = [...p].filter(bx => !(bx?.table ===tableName && bx?.row_name ===rowName) )
				// 	return tpbx
				// })
				settablesExtracted(prev => {
					let tempTables = { ...prev }
					delete tempTables[tableName][rowName]
					return tempTables
				})
				if (DocLockedForMe){
					setchangeDetect(true)
					setchangeAdd(p=>p+1)
				}
			} else {
				// User clicked "No" button or closed the dialog
				// Add any additional logic you want when the user clicks "No" or closes the dialog
			}
		});
	}

	function handleValChange(tableName , row_name , colInd , value) {
		settablesExtracted(prev => {
			let tempTables = { ...prev }
			tempTables[tableName][row_name][colInd] = {...tempTables[tableName][row_name][colInd] , value : value}
			return tempTables
		})
		if (DocLockedForMe){
			setchangeDetect(true)
			setchangeAdd(p=>p+1)
		}
	}

	function handleLableChange(tableName , colInd , value) {
		settablesExtracted(prev => {
			let tempTables = { ...prev }
			Object.keys(tempTables[tableName]).map(row => {
				tempTables[tableName][row][colInd] = {...tempTables[tableName][row][colInd] , entity : value.toLocaleUpperCase()}
			return null 
			})
			return tempTables
		})
		if (DocLockedForMe){
			setchangeDetect(true)
			setchangeAdd(p=>p+1)
		}
	}





	return (
		<>
			{/* Below - List loop of #Table */}

			<div key='table_section' className={`${styles.invFieldsWrap} overflow-auto py-4 pl-6`}>
				{tablesExtracted !== null &&
					Object.keys(tablesExtracted).map((tableName) => {
						let table = tablesExtracted[tableName];
						return (
							<div key={`tb_name_${tableName}`}>
								<TableDropdown IsCSV={IsCSV} settablesExtracted={settablesExtracted} tableName={tableName} setisDeleteEnable={setisDeleteEnable} isDeleteEnable={isDeleteEnable} setchangeDetect={setchangeDetect} setchangeAdd={setchangeAdd} DocLockedForMe={DocLockedForMe} />
								<div className={`${styles.boxTableCtnr} overflow-auto mb-4 py-2`}>
									<table className={styles.bxTable}>
										<tbody>
										{Object.keys(table).map((row_name, rowInd) => {
											let row_lst = table[row_name];
											return (
												<Fragment key={`table_row_${rowInd}`} >
													{rowInd === 0 && (
														<tr key={`${tableName}_${row_name}_lable`}>
															{row_lst.length !==0 &&
															<td className={`${styles.stik} w-10 text-right`}>
																<span className='inline-block text-center rounded-md size-6 leading-6 bg-gray-300 border border-gray-400 text-sm'>#</span>
															</td> }
															{row_lst.map((cell, colInd) => {
																return (
																	<td key={`${tableName}_${row_name}_${colInd}_lable`}>
																		<div className={`${styles.lblBdr} flex gap-2 w-44`}>
																			{/* <button
																				disabled={IsCSV || !DocLockedForMe}
																				onClick={() =>
																					DeleteColPop(tableName, cell.entity)
																				}
																				className="leading-none mt-[2px]"
																			>
																				{isDeleteEnable[tableName] && <span className="material-icons-outlined text-sm text-red-600">
																					cancel
																				</span>}
																			</button> */}
																			<div className="grow">
																				<input 
																					title={cell.entity}
																					disabled={IsCSV || !DocLockedForMe}
																					type="text"
																					// className={styles.invFldInput}
																					className={`${styles.text2Lines} font-bold text-sm outline-none`}
																					value={cell.entity}
																					onChange={(e)=>handleLableChange(tableName , colInd , e.target.value)}
																				/>
																					
																				
																			</div>
																		</div>
																	</td>
																);
															})}
														</tr>
													)}


													<tr key={`${tableName}_${row_name}`}>
														{row_lst.length !==0 &&
														<td className={`${styles.stik}`}>
															<div className='flex gap-2'>
																<button
																	disabled={IsCSV || !DocLockedForMe}
																	onClick={() => DeleteRowPop(tableName, row_name)}
																	className={`${styles.cnclCell}`}
																>
																	{isDeleteEnable[tableName] && <span className="material-icons-outlined text-xs text-red-600">
																		cancel
																	</span>}
																</button>
																<span className='inline-block text-center rounded-md size-6 leading-6 bg-gray-300 border border-gray-400 text-sm'>
																	{rowInd + 1}
																</span>
															</div>
														</td> }
														{row_lst.map((cell, colInd) => {
															return (

																<td key={`${tableName}_${row_name}_${colInd}`}>
																	<div className={`${styles.tdInputBx} block w-44`}>
																		<input
																			title={cell.value}
																			disabled={IsCSV || !DocLockedForMe}
																			type="text"
																			className={styles.invFldInput}
																			value={cell.value}
																			onChange={(e)=>handleValChange(tableName , row_name , colInd , e.target.value)}
																		/>
																		
																		<div className={styles.fltIco}>
																		{MismatchIds[PageNo][cell?.mismatch_id] && ['Approved', 'Pending', 'true'].includes(MismatchIds[PageNo][cell?.mismatch_id]) &&  cell.mismatch_id && 
																				<span title={MismatchIds[PageNo][cell?.mismatch_id]} onClick={()=>gotoMismatch(cell?.mismatch_id)} 
																				className="exclamation-icon cursor-pointer ">
																				
																				</span>
																			}
																			{cell.entity_valid === 'true' && (
																				<span
																					title={
																						cell.entity_validation_explanation
																					}
																					className="material-icons cursor-default text-green-500 text-xs"
																				>
																					check_circle
																				</span>
																			)}
																			{cell.entity_valid === 'false' && (
																				<span
																					title={
																						cell.entity_validation_explanation
																					}
																					className="material-icons cursor-default text-red-500 text-xs"
																				>
																					check_circle
																				</span>
																			)}
																			{cell.entity_valid === 'none' && (
																				<span
																					title={
																						cell.entity_validation_explanation
																					}
																					className="material-icons cursor-default text-gray-500 text-xs"
																				>
																					check_circle
																				</span>
																			)}
																			
																		</div>
																	</div>
																</td>
															);
														})}
													</tr>

												</Fragment>

											);
										})}
										</tbody>
										
									</table>
								</div>
							</div>
						);
					})}

				{/* <button type='button'
                    className={`${styles.bgPrimary} hover:bg-indigo-400 pl-1 pr-2 py-0 rounded-md text-white text-xs hover:text-white flex items-center font-black`}>
                    <span className="material-icons text-sm mr-1">add</span>Add Table
                </button> */}
			</div>
			{/* {IsOpened && (
				<PopUpAddColName
					IsOpened={IsOpened}
					setIsOpened={setIsOpened}
					tablesExtracted={tablesExtracted}
					settablesExtracted={settablesExtracted}
					table={tb}
				/>
			)} */}

			{/* <div className='flex gap-3 justify-end px-6 py-4'>
                <div>
                    <button type='button'
                        className='bg-blue-200 hover:bg-indigo-400 px-4 py-2 rounded-md text-primary text-sm hover:text-white flex items-center font-black'>
                        <span className="material-icons text-sm mr-1">add</span>Add
                    </button>
                </div>
                <div>
                    <button type='button'
                        className='bg-blue-200 hover:bg-indigo-400 px-4 py-2 rounded-md text-primary text-sm hover:text-white flex items-center font-black flex items-center'>
                        <span className="cIco createBoxIco mr-1"></span>Create Box
                    </button>
                </div>
            </div> */}
		</>
	);
}

export default TableList;
