import { Link, Outlet, useSearchParams } from 'react-router-dom';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useBuildModel, useGetBuildModelStatus, useVendorsListPagewise } from './hooks';
import { useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { RECORDS_PER_PAGE } from '../../config';
//import { PopupAddVendor } from './popupAddVendor';
import LoaderFull from '../shared/loader-full-screen';
import { useDebouncedState } from '@mantine/hooks';
import { toast } from 'react-toastify';
import { decodeName, handleSearchKeys, handleSortUtil } from '../utils';
import { useGetLicenceStatus } from '../upload-docs/hooks';
const SORT_KEY_CONST = 'vendor_list_sort_key'
const SORT_ORDER_CONST = 'vendor_list_sort_order'
const PAGE_NO_CONST = 'vendor_list_page_no'
const CLIENT_ID = 'client_id'

function ListVendorsIndex() {
	const [searchParams, setSearchParams] = useSearchParams();

	const [DebounceSearch, setDebounceSearch] = useDebouncedState('', 500);
	const PrivilagesList = useSelector(
		(state) => state?.privilageSlice?.allowedActions
	);

	const get_model_status_hook = useGetBuildModelStatus()
	const licence_check = useGetLicenceStatus()

	const build_model_hook = useBuildModel()
	const [selectedVendorIds, setselectedVendorIds] = useState([])
	const [buildModelLogsStatus, setbuildModelLogsStatus] = useState('')
	const [expectedETA, setexpectedETA] = useState(null)
	const [disableList, setdisableList] = useState([])
	const [isValidLicence, setisValidLicence] = useState(false)


	//const [IsOpened, setIsOpened] = useState(false);

	const isFirstRender = useRef(true)
	
	
	const [Sort, setSort] = useState(handleSearchKeys(searchParams,SORT_KEY_CONST,SORT_ORDER_CONST,PAGE_NO_CONST,CLIENT_ID ));	
	
	const {hook: vendor_list_hook ,setArgs} = useVendorsListPagewise(Sort)


	function handleSort(typer) {
		let {sort_key ,sort_order} = handleSortUtil(typer , Sort)
		searchParams.set(SORT_KEY_CONST, sort_key)
		searchParams.set(SORT_ORDER_CONST, sort_order)
		setSearchParams(searchParams)

	}

	useEffect(() => {
		if (!isFirstRender.current) {
			let newSort = handleSearchKeys(searchParams, SORT_KEY_CONST, SORT_ORDER_CONST, PAGE_NO_CONST)
			setSort(newSort)
			setArgs((p) => {
				return {
					...p,
					...newSort
				}
			})
		}
		isFirstRender.current = false


	}, [searchParams, setArgs])


	const renderList = useCallback(() => {
		if (vendor_list_hook.isFetching) {
			return <LoaderFull />;
		} else if (vendor_list_hook.data?.data?.length === 0) {
			return <p className="text-center">No data found</p>;
		} else {
			return (
				<Outlet
					context={{
						vendorList: vendor_list_hook.data,
						Search: DebounceSearch,
						selectedVendorIds: selectedVendorIds,
						setselectedVendorIds: setselectedVendorIds,
						disableList: disableList
					}}
				/>
			);
		}
	}, [vendor_list_hook,selectedVendorIds, DebounceSearch, disableList]);

	useEffect(() => {
        if (licence_check.fetchStatus === 'idle') {
            if (licence_check.status === 'success') {
                if ("valid" === licence_check?.data?.message) {
                    setisValidLicence(true)
                }
                else if (licence_check?.data?.status ===403){
                    setisValidLicence(false)
                }
               
            }
            else if (licence_check.status === 'error') {
                
            }
            
        }

    }, [licence_check.fetchStatus, licence_check.status, licence_check?.data])
	
	
	function checkForEnable(itm) {
		if (itm.disable) {
			return false
		}
		else if (disableList.some(item => item.vendor_id=== itm._id.vendor_id?.$oid && item.category_id=== itm._id.category_id?.$oid  )){
			return false
		}
		else if(itm.total_invoices===0){
			return false
		}
		else if (itm._id.vendor_id ==null || itm._id.category_id == null) {
			return false
		}
		else{
			return true
		}
		
	}

	function handleCheckAll(checked) {
		if (checked) {
			setselectedVendorIds([...vendor_list_hook?.data?.data?.filter(itm => checkForEnable(itm) ).map(itm => {
				return {
					vendor_id: itm._id.vendor_id?.$oid,
					category_id: itm._id.category_id?.$oid
				}
			})])
		}
		else {
			setselectedVendorIds([])
		}
	}

	function handleBuildModelClicked() {
		if (selectedVendorIds.length === 0) {
			toast.error(`Select Entries from the list`);
			return
		}
		else {
			let body = {
				data: {

					client_id: searchParams.get('client_id'),
					vendor_ids: selectedVendorIds
				}
			}
			setbuildModelLogsStatus('')
			let ids = JSON.parse(JSON.stringify(selectedVendorIds))
			setdisableList(ids)
			build_model_hook.mutate(body)
		}
	}

	useEffect(() => {
		if (build_model_hook.isSuccess ) {
			setselectedVendorIds([])
		}
		else if (build_model_hook.isError) {
			setdisableList([])
		}
	}, [build_model_hook.isSuccess, build_model_hook.isError])

	useEffect(() => {
		if (vendor_list_hook.fetchStatus ==='idle' && vendor_list_hook.status ==='success' ) {
			setdisableList([])
		}
	}, [vendor_list_hook.fetchStatus,vendor_list_hook.status])
	

	useEffect(() => {
		if (get_model_status_hook.fetchStatus ==='idle' && get_model_status_hook.status ==='success' ) {
			setbuildModelLogsStatus(get_model_status_hook?.data?.message)
			setexpectedETA(get_model_status_hook?.data?.eta)

		}
	}, [get_model_status_hook.fetchStatus,get_model_status_hook.status, get_model_status_hook.data]);


	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				{/* Right Side - White workspaces */}
				<>
					<div className="lg:flex gap-4 justify-between items-center vLBG py-4 px-6">
						<div>
							<h1 className="text-lg font-normal text-primary">
								List of <strong className="font-black">Vendors</strong>
							</h1>
							<p className="text-xs text-gray-500 mb-2">
								<Link to={`/my-clients?client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}`}>My Clients</Link> &nbsp; {'>'} &nbsp;
								
								Client - <span title={decodeName(searchParams.get('client_name'))}>{decodeName(searchParams.get('client_name'))}</span>
							</p>
						</div>
						<div className="flex gap-3 flex-wrap">
							<div className="flex gap-2">
								<div className="relative">
									<span className="material-icons absolute top-2 left-2 z-10 text-slate-400 text-md">
										search
									</span>
									<input
										type="text"
										onChange={(e) => {
											setDebounceSearch(()=> {
												setArgs((p) => {
													return { ...p, search: e.target.value, page: 1, sort: '' };
												});
												return e.target.value
											});
										}}
										className="border relative border-slate-300 rounded-md w-full h-10 py-2 pr-2 pl-10 focus:outline-blue-400 text-sm"
										placeholder="Search..."
									/>
								</div>
							</div>
							<div>
								{PrivilagesList.includes('trigger_build_model') &&
								<div className='flex gap-0'>
									<button
										type="button"
										disabled={get_model_status_hook.isFetching}
										onClick={() => {
											get_model_status_hook.refetch()
											setbuildModelLogsStatus('')
											licence_check.refetch()
										}
										}
										className="bg-blue-800 hover:bg-indigo-700 size-10 rounded-l-md border-r border-black text-white flex items-center justify-center font-black disabled:bg-slate-300 disabled:text-slate-700"
									>
										<span className="material-icons text-sm">refresh</span>
									</button>
									<div className='relative '>
										{buildModelLogsStatus === 'In Progress' ? 
										
										<div className='w-[152px] h-full text-center bg-white text-xs flex items-center justify-center rounded-r-md font-bold text-slate-600 px-2 buildStat'>
											{expectedETA}
										</div>
											:
										<button
											disabled={!['Completed', 'Failed'].includes(buildModelLogsStatus) || !isValidLicence  || get_model_status_hook.isFetching || build_model_hook.isPending || licence_check.isFetching}
											onClick={() => handleBuildModelClicked()}
											type="button"
											className="w-[152px] text-center bg-blue-800 hover:bg-indigo-700 block px-4 h-[40px] rounded-r-md text-sm font-black text-white disabled:bg-slate-300 disabled:text-slate-700"
										>
											{!['Completed', 'Failed'].includes(buildModelLogsStatus) || get_model_status_hook.isFetching || build_model_hook.isPending ? 'Build in Progress' : 'Build Model'}
										</button> }

										<div className='absolute text-center text-sm top-[115%] left-0 text-gray-700 flex items-center buildStat w-[152px] justify-center'>
											{buildModelLogsStatus === 'Completed' &&
												<>
													<span className="material-icons-outlined text-green-600">check_circle</span>{buildModelLogsStatus}
												</>
											}
											{buildModelLogsStatus === 'In Progress' &&
												<>
													<span className="material-icons-outlined text-yellow-600">info</span>{buildModelLogsStatus}
												</>
											}
											{buildModelLogsStatus === 'Failed' &&
												<>
													<span className="material-icons-outlined text-red-600">cancel</span>{buildModelLogsStatus}
												</>
											}
										</div>
									</div>
								</div> }
							</div>
							{/* <div>
								{PrivilagesList.includes('add_vendor') && (
									<button
										type="button"
										onClick={() => setIsOpened(true)}
										className="bg-blue-800 hover:bg-indigo-700 flex items-center px-4 h-[40px] text-sm font-black rounded-md text-white"
									>
										<span className="material-icons text-white text-sm mr-1">
											add
										</span>
										Add New Vendor
									</button>
								)}
							</div> */}
						
						</div>
					</div>
				</>

				<>
					<div className="p-6">
						<div className="listDHeight overflow-auto">
							<div className="flex venL gap-2 mb-2">
								<div className="flex-none w-[40px]">
									<Checkbox disabled={vendor_list_hook.isFetching} checked={vendor_list_hook?.data?.data?.filter(itm => checkForEnable(itm) ).length === selectedVendorIds.length && selectedVendorIds.length !== 0} onChange={(e) => handleCheckAll(e.target.checked)} size="small" />
									<div className="text-[9px] text-center">Select All</div>
								</div>
								<div className="flex-none w-[55px]">
									<div className="flex-none text-center relative w-[55px]">
									<span title='default sort' onClick={() => handleSort('')} className={`${Sort.type === '' ? 'text-primary' : 'cursor-pointer'}   material-icons w-4 text-sm mt-1`}>											swap_vert
										</span>
									</div>
								</div>
								<div className="grow px-5 py-1 flex items-center justify-between text-xs">
									<div className="grow pr-4">
										<label
											onClick={() => handleSort('name')}
											className={`${Sort.type === 'name' ? 'text-primary' : ''} font-black block relative w-[120px] cursor-pointer`}
										>
											Name of Vendor
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[140px]">
										<label
											onClick={() => handleSort('category_name')}
											className={`${Sort.type === 'category_name' ? 'text-primary' : ''} w-[100px] font-black block relative cursor-pointer`}
										>
											Expense Category
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[5px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[110px]">
										<label
											onClick={() => handleSort('human_annotation')}
											className={`${Sort.type === 'human_annotation' ? 'text-primary' : ''} w-[100px] font-black block relative cursor-pointer`}
										>Human Annotations
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[5px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[90px]">
										<label
											onClick={() => handleSort('total_invoices')}
											className={`${Sort.type === 'total_invoices' ? 'text-primary' : ''} font-black block relative cursor-pointer`}
										>
											Total Invoices
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[5px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[110px]">
										<label
											onClick={() => handleSort('total_contracts')}
											className={`${Sort.type === 'total_contracts' ? 'text-primary' : ''} font-black block relative cursor-pointer`}
										>
											Total Contracts
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[5px]">
												swap_vert
											</span>
										</label>
									</div>
						
									<div className="flex-none px-2 text-center w-[120px]">
										<label
											onClick={() => handleSort('percentage_complete')}
											className={`${Sort.type === 'percentage_complete' ? 'text-primary' : ''} font-black block relative cursor-pointer`}
										>% Processing Complete
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[5px]">
												swap_vert
											</span>
										</label>
									</div>
								</div>
								<div className="flex-none w-[281px] pr-2">&nbsp;</div>
							</div>

							{renderList()}
						</div>
						<div className="flex justify-center gap-3 pt-2">
							{!vendor_list_hook.isLoading && (
								<Stack spacing={2}>
									<Pagination
										page={parseInt(searchParams.get(PAGE_NO_CONST))}
										onChange={(e, value) => {
											searchParams.set(PAGE_NO_CONST, value)
											setSearchParams(searchParams)
											
										}}
										count={Math.ceil(
											vendor_list_hook.data?.total_vendors /
											RECORDS_PER_PAGE
										)}
										color="primary"
										variant="outlined"
										shape="rounded"
									/>
								</Stack>
							)}
						</div>

						{/* {IsOpened && (
							<PopupAddVendor
								isOpened={IsOpened}
								setIsOpened={setIsOpened}
								vendor_list_hook={vendor_list_hook}
								client_id={searchParams.get('client_id')}
							/>
						)} */}
					</div>
				</>
			</div>
		</>
	);
}

export default ListVendorsIndex;
