import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { BACKEND_URL } from '../../config';
import { useEffect, useState } from 'react';
import { buildAxiosInstance } from '../axiosInstance';
import { LogoutCheck } from '../utils';
import { useMsal } from '@azure/msal-react';

export const useGetInvDesc = (doc_id) => {
	const controller = new AbortController();
    const signal = controller.signal;
	const { instance } = useMsal();
	let dispatch = useDispatch();
	let hook=  useQuery({
		queryKey: [`inv_desc_${doc_id}`],
		retry: false,
		queryFn: async () => {
			try {
				const TOAST_ID = 'invoice_details'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					params: {document_id: doc_id},
					signal: signal,
				};
				const responseData = await axiosInstance.get('/invoice_details',config)
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)	
				
			}
		},
	});
	return {
		hook: hook , 
		controller: controller
	}
};

async function callApiRecursive(body, nthChunk, chunkSize , totalPages) {
	
	try {
		let readyPages = Array.from({ length: chunkSize }, (_, index) => nthChunk*chunkSize + index)
		let pageContentObj = Object.keys(body.pages.pages).reduce((acc, key) => {
			if (readyPages.includes(parseInt(key))) {
			  acc[key] = body.pages.pages[key];
			}
			return acc;
		  }, {});

		let newBody = {
			...body,
			pages: {
				...body.pages,
				pages: pageContentObj
			},
			is_last: (nthChunk+1)*chunkSize < totalPages ? false: true
		}

		const response = await axios.put(
			`${BACKEND_URL}/update_invoice_entities`,
			newBody,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization:
						'Bearer ' + localStorage.getItem('azure_access_token'),
				}
		}
		);

		if (response.status >= 200 && response.status < 300) {
			if ((nthChunk+1)*chunkSize < totalPages) { 
				return callApiRecursive(body, nthChunk+1, chunkSize , totalPages)
			}
			else{
				return response.data;
			}
		} else {
			throw new Error(`Server responded with status ${response.status}`);
		}
	} catch (error) {
		if (error.response?.data?.message !==undefined) { 
			throw new Error(`${error.response.status}|${error.response?.data?.message}`);
		}
		else{
			throw new Error(`444|Failed to update`);
		}
	}
}



export const useUploadInvoiceDesc = () => {
	//const Data = useSelector(state => state.auth)
	return useMutation({
		retry: 1,
		mutationFn:({body , chunkSize, totalPages})=> callApiRecursive(body,0, chunkSize, totalPages)
	});
};


async function callApiRecursiveSaveAsDraft(body, nthChunk, chunkSize , totalPages) {
	
	try {
		let readyPages = Array.from({ length: chunkSize }, (_, index) => nthChunk*chunkSize + index)
		let pageContentObj = Object.keys(body.pages.pages).reduce((acc, key) => {
			if (readyPages.includes(parseInt(key))) {
			  acc[key] = body.pages.pages[key];
			}
			return acc;
		  }, {});

		let newBody = {
			...body,
			pages: {
				...body.pages,
				pages: pageContentObj
			},
			is_last: (nthChunk+1)*chunkSize < totalPages ? false: true
		}
		// newBody.pages = 

		const response = await axios.put(
			`${BACKEND_URL}/invoice_save_as_draft`,
			newBody,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization:
						'Bearer ' + localStorage.getItem('azure_access_token'),
				}
		}
		);

		if (response.status >= 200 && response.status < 300) {
			if ((nthChunk+1)*chunkSize < totalPages) { 
				return callApiRecursiveSaveAsDraft(body, nthChunk+1, chunkSize , totalPages)
			}
			else{
				return response.data;
			}
		} else {
			throw new Error(`Server responded with status ${response.status}`);
		}
	} catch (error) {
	
		if (error.response?.data?.message !==undefined) { 
			throw new Error(`${error.response.status}|${error.response?.data?.message}`);
		}
		else{
			throw new Error(`444|Failed to update`);
		}
	}
}

export const useSaveAsDraft = () => {
	return useMutation({
		retry: 1,
		mutationFn:({body , chunkSize, totalPages})=> callApiRecursiveSaveAsDraft(body,0, chunkSize, totalPages)
	});
};

export const useGetImgUrlByPage = (imgUrls) => {
	const { instance } = useMsal();
	let dispatch = useDispatch();
	const [imgObj, setimgObj] = useState(() => {
		let ret = {};
		Object.keys(imgUrls).map((pgn) => {
			ret[pgn] = {
				img_blob: imgUrls[pgn],
				img_url: null,
			};
		return null 
		});
		return ret;
	});

	const hook = useMutation({
		mutationFn: async (page) => {
			if (imgObj[page].img_url !== null) {
				return {
					connection_string: imgObj[page].img_url,
				};
			} else {

				try {
					const TOAST_ID = 'sas_url_by_blob_key'
					const axiosInstance = buildAxiosInstance(TOAST_ID)
					let config = {
						params: {blob_name: imgObj[page].img_blob},
					};
					const responseData = await axiosInstance.get('/sas_url_by_blob_key',config)
					setimgObj((prev) => {
						return {
							...prev,
							[page]: {
								...prev[page],
								img_url: responseData['connection_string'],
							},
						};
					});
					return responseData
				} catch (error) {
					LogoutCheck(error, dispatch,instance)	
					
				}
			}},
		retry: false,
	});
	return { hook, imgObj, setimgObj };
};



export const useOnlineStatus = () => {
  const [online, setOnline] = useState(typeof window !== "undefined" ? window.navigator.onLine : true);

  useEffect(() => {
    const handleStatusChange = () => {
      setOnline(navigator.onLine);
    };

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, []);

  return online;
};

export const useGetInvoiceStatus = (doc_id) => {
	const { instance } = useMsal();
	let dispatch = useDispatch();
	return useQuery({
		queryKey: [`inv_status_${doc_id}`],
		retry: 3,
		queryFn: async ({signal}) => {
			try {
				const TOAST_ID = 'get_invoice_status'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					params: {document_id : doc_id},
					signal: signal,
				};
				const responseData = await axiosInstance.get('/get_invoice_status',config)
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)	
				
			}
		},
		
	});
};


export const useUpdateLockWindow = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		retry:false,
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'update_my_lock_window'
				const axiosInstance = buildAxiosInstance(TOAST_ID,true)
				const responseData = await axiosInstance.post(`/update_my_lock_window`, JSON.stringify(body));
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)
			}
		},
	});
};

export const useGetMismatchStatusByIds = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		retry:false,
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'get_mismatch_meta_detail_by_ids'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.get(`/get_mismatch_meta_detail_by_ids?${body['search']}`)
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)	
			}

		},
	});
};

export const useGetRules = (categoryID , vendorID) => {
	const { instance } = useMsal();
	let dispatch = useDispatch();
	return useQuery({
		queryKey: [`rules_status_`, categoryID , vendorID ],
		retry: 3,
		queryFn: async ({signal}) => {
			try {
				const TOAST_ID = 'get_rules_inside_invoice'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					params: {vendor_id : vendorID , category_id : categoryID},
					signal: signal,
				};
				const responseData = await axiosInstance.get('/get_rules',config)
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)	
				
			}
		},
		
	});
};