import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { buildAxiosInstance } from '../axiosInstance';
import { LogoutCheck } from '../utils';
import { toast } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
export const useFetchDashboardData = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'fetch_dasboard_data'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`dashboard?analyst=${body.analyst}&category=${body.category}&datefrom=${body.datefrom}&dateto=${body.dateto}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
				
			}
		},
	});
	 
};

export const useFetchInvoiceNeedAttention = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'fetch_invoice_need_attention'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`invoices_need_attention?analyst=${body.analyst}&category=${body.category}&datefrom=${body.datefrom}&dateto=${body.dateto}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
	 
};


export const useFetchMismatches = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'fetch_mismatches'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`fetch_mismatches?analyst=${body.analyst}&category=${body.category}&datefrom=${body.datefrom}&dateto=${body.dateto}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
	 
};