

import React, { useState, useEffect } from 'react';
import styles from '../shared/common.module.css';
import Papa from 'papaparse';

const CsvDataDisplay = ({csvUrl}) => {
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // URL of the CSV file
    // const csvUrl = 'https://prpdev.blob.core.windows.net/csv-files-uplods/invoice/mismatch_data.csv?se=2024-12-31T16%3A35%3A42Z&sp=r&sv=2024-05-04&sr=b&sig=f4yvDc%2BYJFTKUCd6jT0o61pnrMJhx/aWhpSzC9BPFBU%3D';

    // Fetch the CSV data
    if (csvUrl ==null){
        return
    }
    setLoading(true)
    fetch(csvUrl)
      .then(response => response.text()) // Convert the response to text (CSV content)
      .then(csvText => {
        // Parse the CSV text
        Papa.parse(csvText, {
          complete: (result) => {
            // Update state with the parsed data
            setCsvData(result.data);
            setLoading(false);
          },
          error: (err) => {
            // Handle errors during parsing
            setError('Error parsing CSV data');
            setLoading(false);
          }
        });
      })
      .catch((err) => {
        // Handle fetch errors
        setError('Error fetching CSV file');
        setLoading(false);
      });
  }, [csvUrl]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <table className={styles.csvTable}>
        <thead>
          <tr>
            {csvData[0] && csvData[0].map((header, idx) => (
              <th key={idx}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {csvData.slice(1).map((row, idx) => (
            <tr key={idx}>
              {row.map((cell, idx) => (
                <td key={idx}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CsvDataDisplay;
