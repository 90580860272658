import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FieldList from './fields/fieldList';
import TableList from './tables/tableList';
import ChatIndex from './chats/chatIndex';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';

function RightSideInvAcc({
	fieldsExtracted,
	setfieldsExtracted,
	tablesExtracted,
	settablesExtracted,
	UploadInvDesc,
	totalTablesExtracted,
	settotalTablesExtracted,
	totalFieldsExtracted,
	settotalFieldsExtracted,
	setPageNo,
	type,
	settype,
	SaveAsDraft,
	contract_save_as_draft,
	settableChanged,
	PageNo,
	upload_inv_desc,
	errorPages,
	seterrorSelected,
	errorSelected,
	setchangeDetect,
	DocLockedForMe,
	setchangeAdd,
	Freez,
	parentContractId,
	tableBoxes,
	settableBoxes,
	ExtractTable,
	extract_table_hook,
	settableTitle,
	contractName,
	setmovePage,
	ExtractionError,
	setExtractionError,
	IsCSV
}) {
	const PrivilagesList = useSelector(
		(state) => state?.privilageSlice?.allowedActions
	);

	const handleChange = (panel) => (event, isExpanded) => {
		settype(isExpanded ? panel : false);
		
	};


	function MarkAsCompletePop() {
		swal('The document will become read-only once marked as complete. Are you sure you want to proceed?', {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				UploadInvDesc()

			} 
		});
	}

	return (
		<>
			{fieldsExtracted == null ? (
				<p>...</p>
			) : (
				<>
					<div className="px-4 py-6 flex gap-3 justify-between">
						

						<div className='flex gap-2 justify-end'>
							<div>
								{!IsCSV && PrivilagesList.includes('annotate_document') && (
									<button
										disabled={IsCSV || Freez || !DocLockedForMe || contract_save_as_draft.isPending || sessionStorage.getItem('contract_disableSaveBtn')==='true'}
										onClick={SaveAsDraft}
										title={sessionStorage.getItem('contract_disableSaveBtnTitle')}
										type="button"
										className="bg-blue-800 hover:bg-indigo-700 px-3 py-2 rounded-md text-white disabled:bg-gray-200 disabled:text-gray-500 disabled:hover:text-gray-500 text-sm flex items-center font-medium whitespace-nowrap overflow-hidden text-ellipsis"
									>
										<span className="material-icons text-sm mr-1">check</span>
										Save as Draft
									</button>
								)}
							</div>

							<div>
								{!IsCSV && PrivilagesList.includes('annotate_document') && (
									<button
										disabled={IsCSV || Freez || !DocLockedForMe || upload_inv_desc.isPending || sessionStorage.getItem('contract_disableSaveBtn')==='true'}
										onClick={MarkAsCompletePop}
										title={sessionStorage.getItem('contract_disableSaveBtnTitle')}
										type="button"
										className="bg-blue-800 hover:bg-indigo-700 px-3 py-2 rounded-md text-white disabled:bg-gray-200 disabled:text-gray-500 disabled:hover:text-gray-500 text-sm flex items-center font-medium whitespace-nowrap overflow-hidden text-ellipsis"
									>
										<span className="material-icons text-sm mr-1">check</span>
										Mark as Complete
									</button>
								)}
							</div>
						</div>





						
					</div>
					<div className="invoiceAcc grow flex flex-col">
						<Accordion
							expanded={type === 'extracted_entities'}
							onChange={handleChange('extracted_entities')}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography className="flex items-center">
									<span className="material-icons text-black text-sm mr-3">
										notes
									</span>
									Fields
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<FieldList
									fieldsExtracted={fieldsExtracted}
									setfieldsExtracted={setfieldsExtracted}
									DocLockedForMe={DocLockedForMe}
									setchangeDetect={setchangeDetect}
									setchangeAdd={setchangeAdd}
									Freez={Freez}
									IsCSV={IsCSV}
								/>
							</AccordionDetails>
						</Accordion>
						<Accordion
							expanded={type === 'tables'}
							onChange={handleChange('tables')}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography className="flex items-center">
									<span className="material-icons text-black text-sm mr-3">
										grid_4x4
									</span>
									Tables
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<TableList
									tablesExtracted={tablesExtracted}
									settablesExtracted={settablesExtracted}
									DocLockedForMe={DocLockedForMe}
									setchangeDetect={setchangeDetect}
									setchangeAdd={setchangeAdd}
									settableChanged={settableChanged}
									PageNo={PageNo}
									Freez={Freez}
									settableBoxes={settableBoxes}
									ExtractTable={ExtractTable}
									extract_table_hook={extract_table_hook}
									settableTitle={settableTitle}
									settotalTablesExtracted={settotalTablesExtracted}
									ExtractionError={ExtractionError}
									setExtractionError={setExtractionError}
									IsCSV={IsCSV}
								/>
							</AccordionDetails>
						</Accordion>
						<Accordion
							expanded={type === 'chat'}
							onChange={handleChange('chat')}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography className="flex items-center">
									<span className="material-icons-outlined text-black text-md mr-3">
										forum
									</span>
									Chat
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<ChatIndex 
									parentContractId={parentContractId}
									setmovePage={setmovePage}
 									contractName={contractName}	 
									setchangeAdd={setchangeAdd}/>
									
							</AccordionDetails>
						</Accordion>
					</div>
				</>
			)}
		</>
	);
}

export default RightSideInvAcc;
