import { Link, Outlet, useSearchParams } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import React, { useState, useEffect, useCallback, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useCategoryNameById, useDeleteInvoice, useInvoiceslistPagewise } from './hooks';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { RECORDS_PER_PAGE } from '../../config';
import LoaderFull from '../shared/loader-full-screen';
import { useDebouncedState } from '@mantine/hooks';
import { decodeName, encodeName, handleSearchKeys, handleSortUtil ,  } from '../utils';
import { RemapPopUp } from './remapPop';
const SORT_KEY_CONST = 'invoice_list_sort_key'
const SORT_ORDER_CONST = 'invoice_list_sort_order'
const PAGE_NO_CONST = 'invoice_list_page_no'
const CLIENT_ID = 'client_id'
const VENDOR_ID = 'vendor_id'
const CATEGORY_NAME = 'category_name'
const CATEGORY_ID = 'category_id'


function ListInvoicesIndex() {
	const [searchParams, setSearchParams] = useSearchParams();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClickListItem = (event) => {setAnchorEl(event.currentTarget)}
	const delete_inv_hook = useDeleteInvoice()
	const [IsOpened, setIsOpened] = useState(false)
	const [selectedInvoices, setselectedInvoices] = useState(new Set([]))
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [DebounceSearch, setDebounceSearch] = useDebouncedState('', 500);
	
	const isFirstRender = useRef(true)
	
	
	const [Sort, setSort] = useState(handleSearchKeys(searchParams,SORT_KEY_CONST,SORT_ORDER_CONST,PAGE_NO_CONST,CLIENT_ID, VENDOR_ID ,CATEGORY_NAME, CATEGORY_ID ));	
	
	const {hook:invoices_list_hook ,setArgs} = useInvoiceslistPagewise(Sort)
	const catname_hook = useCategoryNameById(searchParams.get(CATEGORY_ID))


	function handleSort(typer) {
		let {sort_key ,sort_order} = handleSortUtil(typer , Sort)
		searchParams.set(SORT_KEY_CONST, sort_key)
		searchParams.set(SORT_ORDER_CONST, sort_order)
		setSearchParams(searchParams)

	}

	useEffect(() => {
		if (!isFirstRender.current) {
			let newSort = handleSearchKeys(searchParams, SORT_KEY_CONST, SORT_ORDER_CONST, PAGE_NO_CONST)
			setSort(newSort)
			setArgs((p) => {
				return {
					...p,
					...newSort
				}
			})
		}
		isFirstRender.current = false


	}, [searchParams,setArgs])


	const renderList = useCallback(() => {
		if (invoices_list_hook.isFetching || delete_inv_hook.isPending ) {
			return <LoaderFull />;
		} else if (invoices_list_hook.data?.data?.length === 0) {
			return <p className="text-center">No data found</p>;
		} else {
			return (
				<Outlet
					context={{
						invoiceList: invoices_list_hook.data,
						Search: DebounceSearch,
						selectedInvoices: selectedInvoices,
						setselectedInvoices: setselectedInvoices
					}}
				/>
			);
		}
	}, [invoices_list_hook.isFetching,invoices_list_hook.data, DebounceSearch, selectedInvoices, delete_inv_hook.isPending]);



	

	function handleCheckAll(checked) {
		if (checked) {
			setselectedInvoices(new Set(invoices_list_hook?.data?.data.filter(itm => !itm?.csv_upload).map(itm => itm._id) )) 
		}
		else{
			setselectedInvoices(new Set([]))
		}
	}

	function deleteInvClick() {
		let body = { 
			document_ids :Array.from(selectedInvoices)
		}
		delete_inv_hook.mutate(body)
	}

	useEffect(() => {
	  if (delete_inv_hook.isSuccess) { 
		setselectedInvoices(new Set([]))
	  }
	}, [delete_inv_hook.isSuccess])


	
	
	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				<>
					<div className="lg:flex gap-4 justify-between items-center vLBG py-4 px-6">
						<div className="flex-none">
							<h1 className="text-lg font-normal text-primary">
								List of <strong className="font-black">Invoices</strong>
							</h1>
							<p className="text-xs text-gray-500 mb-2">
								<Link to={`/my-clients?client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}`}>My Clients</Link> &nbsp; {'>'} &nbsp;
								<Link
									to={`/my-vendors?client_id=${searchParams.get('client_id')}&client_name=${encodeName(decodeName((searchParams.get('client_name'))))}&client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}&vendor_list_page_no=${searchParams.get('vendor_list_page_no')}&vendor_list_sort_key=${searchParams.get('vendor_list_sort_key')}&vendor_list_sort_order=${searchParams.get('vendor_list_sort_order')}`}
								>
									Client - <span title={decodeName(searchParams.get('client_name'))}>{decodeName(searchParams.get('client_name'))}</span>
								</Link>{' '}
								&nbsp; {'>'} &nbsp;
								
								Vendor - <span title={decodeName(searchParams.get('vendor_name'))}>{decodeName(searchParams.get('vendor_name'))}</span> ( { catname_hook.data ? catname_hook.data?.name: '..' } )
							</p>
						</div>
						<div className="flex gap-3 flex-wrap">
							<div className="flex gap-2">
								
								<div className="relative">
									<span className="material-icons absolute top-2 left-2 z-10 text-slate-400 text-md">
										search
									</span>
									<input
										type="text"
										onChange={(e) => {
											setDebounceSearch(()=> {
												setArgs((p) => {
													return { ...p, search: e.target.value, page: 1, sort: '' };
												});
												return e.target.value
											});
										}}
										className="border relative border-slate-300 rounded-md w-full h-10 py-2 pr-2 pl-10 focus:outline-blue-400 text-sm"
										placeholder="Search..."
									/>
								</div>
							</div>
							
							<div>
								<List component="nav" aria-label="Export">
									<ListItem
										id="export-button"
										aria-haspopup="listbox"
										aria-controls="export-menu"
										aria-expanded={open ? 'true' : undefined}
										onClick={handleClickListItem}
										className='text-white font-bold text-sm h-[40px]'
									>
										Actions 
										<span className="material-icons text-white text-sm ml-1">
											expand_more
										</span>
									</ListItem>
								</List>
								<Menu
									id="export-menu"
									className='expMenu'
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
									MenuListProps={{
										'aria-labelledby': 'export-button',
										role: 'listbox',
									}}
								>
									
									<MenuItem disabled={delete_inv_hook.isPending || selectedInvoices.size ===0} onClick={deleteInvClick}>
										<span className="material-icons text-sm mr-3">
											delete
										</span>
										Delete
									</MenuItem>
									<MenuItem disabled={selectedInvoices.size ===0} onClick={()=> {
                                        handleClose()
                                        setIsOpened(true)}}>
										<span className="material-icons text-sm mr-3">
											redo
										</span>
										Remap
									</MenuItem>
								</Menu>

							</div>
						</div>
					</div>
				</>

				<>
					<div className="p-6">
						<div className="listDHeight larger overflow-auto">
							<div className="flex gap-2 mb-2 items-center pr-4">
								<div className="flex-none w-[40px]">
									<Checkbox disabled={invoices_list_hook.isFetching} checked={selectedInvoices.size === invoices_list_hook?.data?.data?.length && selectedInvoices.size !==0 } onChange={(e)=> handleCheckAll(e.target.checked)} size="small" />
									<div className="text-[9px] text-center">Select All</div>
								</div>
								<div className="flex-none w-[55px]">
									<div className="flex-none text-center relative w-[55px]">
									<span title='default sort' onClick={() => handleSort('')} className={`${Sort.type === '' ? 'text-primary' : 'cursor-pointer'}   material-icons w-4 text-sm mt-1`}>											swap_vert
										</span>
									</div>
								</div>
								<div className="grow px-2 py-1 flex items-center justify-between text-xs">
									<div className="grow pr-4">
										<label
											onClick={() => handleSort('fname')}
											className={`${Sort.type === 'fname' ? 'text-primary' : ''} block font-black relative w-[120px] cursor-pointer`}
										>
											Name of Invoice
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[150px]">
										<label
											className={`font-black block relative w-[160px] cursor-default`}
										>
											Uploaded By
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[140px]">
										<label
											className={`font-black block relative w-[160px] cursor-default`}
										>
											Status
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[150px]">
										<label
											onClick={() => handleSort('uploaded_at')}
											className={`${Sort.type === 'uploaded_at' ? 'text-primary' : ''} font-black block relative w-[150px] cursor-pointer`}
										>
											Date Uploaded
											<span className="material-icons absolute w-4 top-0 right-2 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[190px]">
										<label
											onClick={() => handleSort('updated_at')}
											className={`${Sort.type === 'updated_at' ? 'text-primary' : ''} font-black block relative w-[170px] cursor-pointer`}
										>
											Last Updated Date
											<span className="material-icons absolute w-4 top-0 right-2 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[190px]">
										<label
											onClick={() => handleSort('published_on')}
											className={`${Sort.type === 'published_on' ? 'text-primary' : ''} font-black block relative w-[170px] cursor-pointer`}
										>
											Invoice Generated Date
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[160px]">
										<label
											onClick={() => handleSort('total_correct_entities')}
											className={`${Sort.type === 'total_correct_entities' ? 'text-primary' : ''} font-black block relative w-[148px] cursor-pointer`}
										>
											Data Validated
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[150px]">
									<label
											onClick={() => handleSort('percentage_complete')}
											className={`${Sort.type === 'percentage_complete' ? 'text-primary' : ''} font-black block relative w-[138px] cursor-pointer`}
										>
											% Processing Complete
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[5px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[150px]">
									<label
											onClick={() => handleSort('null_value')}
											className={`${Sort.type === 'null_value' ? 'text-primary' : ''} font-black block relative w-[138px] cursor-pointer`}
										>
											Null values
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>

									<div className="flex-none px-4 text-center w-[150px]">
									<label
											onClick={() => handleSort('total_mismatch_captured')}
											className={`${Sort.type === 'total_mismatch_captured' ? 'text-primary' : ''} font-black block relative w-[138px] cursor-pointer`}
										>
											Mismatches
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>


								</div>
								<div className="flex-none w-[106px] pr-2">&nbsp;</div>
							</div>
							{/* Table Body Row - Loop */}

							{renderList()}
						</div>
						<div className="flex justify-center gap-3 pt-2">
							{!invoices_list_hook.isLoading && (
								<Stack spacing={2}>
									<Pagination
										page={Sort.page}
										onChange={(e, value) => {
											searchParams.set(PAGE_NO_CONST, value)
											setSearchParams(searchParams)
										}}
										count={Math.ceil(
											invoices_list_hook.data?.total_invoices /
												RECORDS_PER_PAGE
										)}
										color="primary"
										variant="outlined"
										shape="rounded"
									/>
								</Stack>
							)}
						</div>
					</div>
				</>
				{IsOpened && (
					<RemapPopUp
						clientID={decodeName(searchParams.get('client_id'))}
						vendorName={decodeName(searchParams.get('vendor_name'))}
						vendorID={decodeName(searchParams.get('vendor_id'))}
						isOpened={IsOpened}
						setIsOpened={setIsOpened}
						invoices_list_hook={invoices_list_hook}
						invoiceIds={Array.from(selectedInvoices)}
					/>
				)}
			</div>
		</>
	);
}

export default ListInvoicesIndex;
