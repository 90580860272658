import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Autocomplete, TextField } from '@mui/material';
import { GetCategorylist, GetAnalystlist } from './../upload-docs/hooks';
import { useSelector } from 'react-redux';
import FltrClientName from './client-name-flt';
import FilterVendorName from './vendor-name';
import FilterBatchName from './batch-name';
import FilterInvoiceName from './invoice-name'
import FilterFieldName from './field-name'
import FilterInvoiceStatus from './invoice-status'

export default function DateFilter({ get_dashboard_hook, get_invoice_need_attention_hook, get_mismatches_hook }) {
	const [fromValue, setFromValue] = React.useState();
	const [ToValue, setToValue] = React.useState();
	const category_list_hook = GetCategorylist();
	const analyst_list_hook = GetAnalystlist();

	const [, setFocus] = useState(false);
	const [options, setoptions] = useState([]);
	const [analystOptions, setAnalystOptions] = useState([]);
	
	const [Category, setCategory] = useState('All Categories');
	const [CategoryId, setCategoryId] = useState(null);

	const [Analyst, setAnalyst] = useState('All Users');
	const [AnalystId, setAnalystId] = useState(null);

	const [ClientName, setClientName] = useState(null);
	const [ClientId, setClientId] = useState(null);

	const [VendorName, setVendorName] = useState(null);
	const [VendorNameId, setVendorNameId] = useState(null);

	const [InvoiceName, setInvoiceName] = useState(null);
	const [InvoiceNameId, setInvoiceNameId] = useState(null);

	const [FieldName, setFieldName] = useState(null);
	const [FieldNameId, setFieldNameId] = useState(null);

	const [BatchName, setBatchName] = useState(null);
	const [BatchNameId, setBatchNameId] = useState(null);

	const [InvoiceStatus, setInvoiceStatus] = useState(null);
	//const [InvoiceNameId, setInvoiceNameId] = useState(null);

	function setDebounceSearch() {
		
		get_dashboard_hook.mutate({ analyst: AnalystId, category: CategoryId,client:ClientId,vendor:VendorNameId,invoice:InvoiceNameId,field:FieldNameId,batch:BatchNameId,invoice_status:InvoiceStatus, datefrom: (fromValue) ? dayjs(fromValue.$d).format("MM-DD-YYYY") : null, dateto: (ToValue) ? dayjs(ToValue.$d).format("MM-DD-YYYY") : null });
		get_invoice_need_attention_hook.mutate({ analyst: AnalystId, category: CategoryId, datefrom: (fromValue) ? dayjs(fromValue.$d).format("MM-DD-YYYY") : null, dateto: (ToValue) ? dayjs(ToValue.$d).format("MM-DD-YYYY") : null });
		get_mismatches_hook.mutate({ analyst: AnalystId, category: CategoryId, datefrom: (fromValue) ? dayjs(fromValue.$d).format("MM-DD-YYYY") : null, dateto: (ToValue) ? dayjs(ToValue.$d).format("MM-DD-YYYY") : null });
	}

	useEffect(() => {
		if (category_list_hook.data) {
			//console.log(category_list_hook.data);
			setoptions(category_list_hook.data.data.map((itm) => itm.name));
		}
	}, [category_list_hook.data]);

	useEffect(() => {
		if (analyst_list_hook.data) {
			//console.log(category_list_hook.data);
			setAnalystOptions(analyst_list_hook.data.data.map((itm) => (itm.name) ? itm.name : itm.email));
		}
	}, [analyst_list_hook.data]);

	function changeCategory(val) {
		//console.log(val);
		setCategory(val);
		let obj = category_list_hook.data.data.find((o) => o.name === val);
		//console.log(obj);
		if (obj) {
			setCategoryId(obj._id);
			//console.log(CategoryId);
		}
		else {
			setCategoryId(null);
		}

	}

	function changeAnalyst(val) {
		//console.log(val);
		setAnalyst(val);
		let obj = analyst_list_hook.data.data.find((o) => o.email === val);
		//console.log(obj);
		if (obj) {
			setAnalystId(obj._id);
			//console.log(CategoryId);
		}
		else {
			let obj = analyst_list_hook.data.data.find((o) => o.name === val);
			//console.log(obj);
			if (obj) {
				setAnalystId(obj._id);
				//console.log(CategoryId);
			}
			else {
				setAnalystId(null);
			}
		}

	}

	const PrivilagesList = useSelector(
		(state) => state?.privilageSlice?.allowedActions
	);

	//console.log(PrivilagesList);

	return (
		<>
			<div className="md:flex items-center gap-3 flex-wrap xl:flex-nowrap text-sm multiDashFltr">

				{PrivilagesList.includes('leader_dashboard') ? (
					<div className="grow missDD">
						{category_list_hook.isPending ? (
							<p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
								<span className="text-xs text-slate-400 ps-4 inline-block">
									Loading..
								</span>
							</p>
						) : (
							<Autocomplete
								isOptionEqualToValue={(option, value) =>
									option === value || value === '' || value == null
								}
								disablePortal
								id="combo-box-demo"
								className='expCat'
								options={analystOptions}
								value={Analyst || null}
								renderInput={(params) => (
									<TextField {...params} label="User" />
								)}
								onChange={(event, value) => changeAnalyst(value)}
								onFocus={() => setFocus(true)}
								onBlur={() => setFocus(false)}
							/>
						)}
					</div>
				) : (
					null
				)}

				<div className="grow missDD">
					{category_list_hook.isPending ? (
						<p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
							<span className="text-xs text-slate-400 ps-4 inline-block">
								Loading..
							</span>
						</p>
					) : (
						<Autocomplete
							isOptionEqualToValue={(option, value) =>
								option === value || value === '' || value == null
							}
							disablePortal
							id="combo-box-demo"
							className='expCat'
							options={options}
							value={Category || null}
							renderInput={(params) => (
								<TextField {...params} label="Expense Area" />
							)}
							onChange={(event, value) => changeCategory(value)}
							onFocus={() => setFocus(true)}
							onBlur={() => setFocus(false)}
						/>
					)}
				</div>

				<FltrClientName
					setClientName={setClientName}
					ClientName={ClientName}
					setClientId={setClientId}
					ClientId={ClientId}
				/>

				<FilterVendorName
					setVendorName={setVendorName}
					VendorName={VendorName}
					setVendorNameId={setVendorNameId}
					VendorNameId={VendorNameId}
					required='false'
				/>

				{(ClientId && VendorNameId && CategoryId) && (
					<FilterInvoiceName
						setInvoiceName={setInvoiceName}
						InvoiceName={InvoiceName}
						setInvoiceNameId={setInvoiceNameId}
						InvoiceNameId={InvoiceNameId}
						ClientId={ClientId}
						CategoryId={CategoryId}
						VendorNameId={VendorNameId}
					/>
				)}
				{(CategoryId && VendorNameId) && (
					<div className="grow missDD">
						<FilterFieldName
							setFieldName={setFieldName}
							FieldName={FieldName}
							setFieldNameId={setFieldNameId}
							FieldNameId={FieldNameId}
							ClientId={ClientId}
							CategoryId={CategoryId}
							VendorNameId={VendorNameId}
						/>
					</div>
				)}

				<FilterBatchName
					setBatchName={setBatchName}
					BatchName={BatchName}
					setBatchNameId={setBatchNameId}
					BatchNameId={BatchNameId}
				/>

				{(ClientId && VendorNameId) && (
					
					<div className="grow missDD">
						<FilterInvoiceStatus
							setInvoiceStatus={setInvoiceStatus}
							InvoiceStatus={InvoiceStatus}
							//setInvoiceNameId={setInvoiceNameId}
							//InvoiceNameId={InvoiceNameId}
							ClientId={ClientId}
							CategoryId={CategoryId}
							VendorNameId={VendorNameId}
						/>
					</div>
				)}

				<div className="dateHldr flex items-center gap-3">
					<div>From</div>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DemoContainer components={['DatePicker']}>
							<DatePicker
								slotProps={{ field: { clearable: true } }}
								value={fromValue || null}
								onChange={(event) => { setFromValue(event); }}
							/>
						</DemoContainer>
					</LocalizationProvider>
				</div>

				<div className="dateHldr flex items-center gap-3">
					<div>To</div>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DemoContainer components={['DatePicker']}>
							<DatePicker
								slotProps={{ field: { clearable: true } }}
								value={ToValue || null}
								onChange={(newValue) => setToValue(newValue)}
							/>
						</DemoContainer>
					</LocalizationProvider>
				</div>
				<div>
					<button
						type="button"
						disabled={get_dashboard_hook.isPending || get_invoice_need_attention_hook.isPending}
						onClick={setDebounceSearch}
						className="bg-blue-800 hover:bg-indigo-700 w-full px-4 py-2 h-10 rounded-md text-white flex items-center font-black disabled:bg-slate-300 disabled:text-slate-700"
					>
						<span className="material-icons mr-1 text-sm">refresh</span>
						Reload
					</button>
				</div>
			</div>
		</>
	);
}