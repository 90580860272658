import React, { useEffect, useState } from 'react';
import TopComboNButtons from './topComboNButtons';
import FieldsNTabularFields from './fieldsNTabularFields';
import { useGetRules, useUpdateRules } from './hooks';
import LoaderFull from '../shared/loader-full-screen';
import swal from 'sweetalert';


const GlobalFieldsManageIndex = () => {  


    const [query, setquery] = useState({})
    const [isGlobalFetch, setisGlobalFetch] = useState(null)


    const get_rules_hook = useGetRules(true)
    const update_rules = useUpdateRules()
    const [Rules, setRules] = useState(null)
    const [loadingData, setloadingData] = useState(false)
    const [selectedSet, setselectedSet] = useState(new Set())
    const [tryTodelete, settryTodelete] = useState(false)

    useEffect(() => {
      console.log(get_rules_hook.data);
      if (get_rules_hook.data?.data) { 
        setloadingData(true)
        let arr = get_rules_hook.data?.data.rules
        arr.sort((a, b) => a.entity_name.localeCompare(b.entity_name));
        setRules(arr.map(itm => {
            itm.checked = false
            return itm
        }))
        setloadingData(false)
      }
      else{
        setRules(null)
      }

      
    }, [get_rules_hook.data])

    function deleteSelected() {
            swal('Are you sure you want to Delete?', {
                buttons: ['No', 'Yes'],
                disabled: {tryTodelete}
            }).then((value) => {
                if (value) {
                    settryTodelete(true)
    
                    // setRules(p => {
                    //     return p.filter(itm => !(itm.entity_name.toLocaleLowerCase() in selectedSet))
                    // })
                }
            });
        }
    





    useEffect(() => {
        get_rules_hook.mutate({ global : true })
    
      
    }, [])
    
    



    
    



    return (
        <>
           
           <TopComboNButtons setselectedSet={setselectedSet} deleteSelected={deleteSelected} tryTodelete={tryTodelete} settryTodelete={settryTodelete} global={true} Rules={Rules} update_rules={update_rules} setisGlobalFetch={setisGlobalFetch} isGlobalFetch={isGlobalFetch} setRules={setRules} query={query} setquery={setquery} get_rules_hook={get_rules_hook} />
            {/* <button disabled={update_rules.isPending || get_rules_hook.isPending || loadingData} onClick={()=> {
                get_rules_hook.mutate({ global : true })
                setRules(null)
            
            }
                
                 } >Refresh</button> */}
            {/* {!query?.vendor_alias_id || !query?.category_id ? <p>Select filters to get rules</p> :  */}
            <>
            { update_rules.isPending || get_rules_hook.isPending || loadingData ? <LoaderFull /> : 
            <>
            {Rules && <FieldsNTabularFields setselectedSet={setselectedSet} selectedSet={selectedSet} tryTodelete={tryTodelete} settryTodelete={settryTodelete} get_rules_hook={get_rules_hook} setRules={setRules} Rules={Rules} /> }
            </>
            }
            </>
            {/* } */}
        </>
    )
}

export default GlobalFieldsManageIndex
