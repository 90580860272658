import React, { useEffect, useState } from 'react';
import DateFilter from './date-filter';
import DashCounters from './dash-counters';
import UploadDonuts from './upload-progress';
import AnnotationsChart from './annotations-chart';
import AnnotationsInProgressChart from './annotations-in-progress'
import InvoiceAttentions from './invoice-attention';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LoaderFull from '../shared/loader-full-screen';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ContractAttentions from './contracts-attention';
import MismatchesdDonuts from './mismatches-progress'
import VendorsList from './top-vendors';

import { useFetchDashboardData,useFetchInvoiceNeedAttention,useFetchMismatches } from './hooks';

function Dashboard() {
	const [DashboardData, setDashboardData] = useState({});
	const [MismatchesData, setMismatchesData] = useState({});
	const [InvoiceData, setInvoiceData] = useState({});
	const [value, setValue] = React.useState(0);
	const [percentageCompleted,setPercentageCompleted]= React.useState(null);
	const [ina, setIna] = React.useState(0);
	const [percentageMismatches, setPercentageMismatches] = React.useState(0);
	
	const get_dashboard_hook = useFetchDashboardData();

	const get_invoice_need_attention_hook = useFetchInvoiceNeedAttention();

	const get_mismatches_hook = useFetchMismatches();

	useEffect(() => {
		get_dashboard_hook.mutate({analyst: null, category: null, client:null, vendor:null, invoice:null, field:null, batch:null, invoice_status:null, datefrom: null, dateto: null});
		get_invoice_need_attention_hook.mutate({analyst: null,category: null, datefrom: null, dateto: null});
		get_mismatches_hook.mutate({analyst: null,category: null, datefrom: null, dateto: null});
	}, []);

	useEffect(() => {
		if (get_dashboard_hook.isError) {
			toast.error(get_dashboard_hook.error?.message);
		} else if (get_dashboard_hook.isSuccess || get_dashboard_hook.data) {
			setDashboardData(get_dashboard_hook.data);
			//console.log(get_dashboard_hook.data);
			
			setPercentageCompleted( ((get_dashboard_hook.data?.total_files_completed/get_dashboard_hook.data?.total_files_uploaded)*100).toFixed(2) )
			//setIna(get_dashboard_hook.data?.invoice_attention_list?.count)
		}
	}, [get_dashboard_hook.isError, get_dashboard_hook.isSuccess, get_dashboard_hook.data, get_dashboard_hook.error?.message]);


	useEffect(() => {
		
		
		if (get_invoice_need_attention_hook.isError) {
			toast.error(get_invoice_need_attention_hook.error?.message);
		} else if (get_invoice_need_attention_hook.isSuccess || get_invoice_need_attention_hook.data) {
			//console.log(get_invoice_need_attention_hook.data);
			setInvoiceData(get_invoice_need_attention_hook.data);
			//console.log(get_dashboard_hook.data);
			
			//setPercentageCompleted( ((get_dashboard_hook.data?.total_files_completed/get_dashboard_hook.data?.total_files_uploaded)*100).toFixed(2) )
			setIna(get_invoice_need_attention_hook.data.invoice_attention_list?.count)
		}
	}, [get_invoice_need_attention_hook.isError, get_invoice_need_attention_hook.isSuccess, get_invoice_need_attention_hook.data, get_invoice_need_attention_hook.error?.message]);

	useEffect(() => {
		
		
		if (get_mismatches_hook.isError) {
			toast.error(get_mismatches_hook.error?.message);
		} else if (get_mismatches_hook.isSuccess || get_mismatches_hook.data) {
			//console.log(get_invoice_need_attention_hook.data);
			setMismatchesData(get_mismatches_hook.data);
			//console.log(get_dashboard_hook.data);
			
			setPercentageMismatches( ((get_mismatches_hook.data?.pending_mismatch/get_mismatches_hook.data?.total_mismatch)*100).toFixed(2) )
			//setIna(get_invoice_need_attention_hook.data.invoice_attention_list?.count)
		}
	}, [get_mismatches_hook.isError, get_mismatches_hook.isSuccess, get_mismatches_hook.data, get_mismatches_hook.error?.message]);


	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const PrivilagesList = useSelector(
		(state) => state?.privilageSlice?.allowedActions
	);

	return (
		<>
			
			<div className="grow fontInter">
				
				<div className="px-6 py-4 mb-4">
					<div className="pb-4">
						<h1 className="text-lg font-black text-primary">Dashboard</h1>
						<p className="text-xs text-gray-500">
							Total data of Clients, Vendors, Invoices, Contracts
						</p>
					</div>
					
					<DateFilter get_dashboard_hook={get_dashboard_hook} get_invoice_need_attention_hook={get_invoice_need_attention_hook} get_mismatches_hook={get_mismatches_hook} />
				</div>
				{/* Total data of Clients, Vendors, Invoices, Contracts */}

				{get_dashboard_hook.isPending ? (
					<LoaderFull />
				) : (
					<DashCounters DashboardData={DashboardData} />
				)}
				<div className="md:flex border-b">
					<div className="md:w-1/2 lg:w-1/3 xl:w-1/4 pl-6 pr-4 py-4 border-b md:border-b-0 md:border-r">
						{/* Processing files */}

						{get_dashboard_hook.isPending ? (
							<LoaderFull />
						) : (
							<div className='donCanVas'>
								<UploadDonuts DashboardData={DashboardData} />
								{percentageCompleted >=0 && ( <label className='donCLbl'>{percentageCompleted}% Processed</label> )}
							</div>
						)}
					</div>
					{!PrivilagesList.includes('analyst_dashboard') ? (
						<div className="md:w-1/2 lg:w-2/3 xl:w-3/4">
							<div className="grid gap-4 grid-cols-1 xl:grid-cols-2">
								{PrivilagesList.includes("all_dashboard") ? (
									<div className="p-4">
										{/* Human Annotations */}
										{(get_dashboard_hook.isPending) ? (
											<LoaderFull />
										) : (
											<AnnotationsChart DashboardData={DashboardData} />
										)}
									</div>
								) : null}
								{PrivilagesList.includes("leader_dashboard") ? (
									<div className="p-4">
										{/* Annotations in progress */}
										{(get_dashboard_hook.isPending) ? (
											<LoaderFull />
										) : (
											<AnnotationsInProgressChart DashboardData={DashboardData} />
										)}
									</div>
								) : null}
								<div className="p-4">
								{/* Top Vendors Annotated */}

								{(get_dashboard_hook.isPending) ? (
									<LoaderFull />
								) : (
									<VendorsList DashboardData={DashboardData} />
								)}
								</div>
							</div>
						</div>

					) : null}
				</div>

				<div className="md:flex border-b">
					<div className="md:w-1/2 lg:w-1/3 xl:w-1/4 pl-6 pr-4 py-4 border-b md:border-b-0 md:border-r">
						{/* Processing files */}

						{get_dashboard_hook.isPending ? (
							<LoaderFull />
						) : (
							<div className='donCanVas'>
								<MismatchesdDonuts MismatchesData={MismatchesData} />
								{percentageMismatches >=0 && ( <label className='donCLbl'>{percentageMismatches}% Mismatched</label> )}
							</div>
						)}
					</div>
					
				</div>

				<div className="p-4">
					<Box sx={{ bgcolor: 'background.paper' }}>
						<Tabs
							value={value}
							onChange={handleChange}
							textColor="primary"
							indicatorColor="primary"
							aria-label="attention tabs"
						>
							{(get_invoice_need_attention_hook.isPending) ? (
									<Tab
									className="text-lg font-black"
									label={"Invoices Need Attention (0)"}
								/>
								) : (
									<Tab
								className="text-lg font-black"
								label={"Invoices Need Attention ("+ina+")"}
							/>
								)}
							
							<Tab
								className="text-lg font-black"
								label="Contracts Need Attention"
							/>
						</Tabs>
					</Box>
					{value === 0 && (
						<div className="px-2 py-4">
							{/* Invoice needing attention */}
							{(get_invoice_need_attention_hook.isPending) ? (
								<LoaderFull />
							) : (
								<InvoiceAttentions DashboardData={InvoiceData} />
							)}
						</div>
					)}
					{value === 1 && (
						<div className="px-2 py-4">
							{/* contract needing attention */}
							<ContractAttentions />
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default Dashboard;